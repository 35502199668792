import React, { useState, useEffect, useCallback, Component, useRef } from "react";
import PropTypes from 'prop-types';
import { Editor as Wysiwyg } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import "../../react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Icon, { IconType } from "../Icon/Icon";
import Modal from "../Modal/Modal";
import { toast } from "react-toastify";
import { UiValuePairs } from "../../utils/CommonTypes/UserTypes";
import { EasyImage, ExpandableImg } from "../Tools/ExpandableDiv";
import { store } from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { initial } from "lodash";
import { IFileInfo } from "../../redux/types";

interface IEditorProps {
  value: string;
  options: "simple" | "full";
  changed:Function;
  height: "normal" | "short";
  onblur?:(value?:any)=>void;
  readOnly?:boolean;
  saveBeforeChange?:boolean;
  close?:()=>void;
  hideExpand?:boolean;
}

const Editor = (props: IEditorProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (props.value) {
      //try{
      let html = WrapHtmlIntoSingleElementIfNeeded(props.value);
      let blocksFromHtml = htmlToDraft(html);
      console.log(blocksFromHtml);
      let { contentBlocks, entityMap } = blocksFromHtml;
      let content = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(content));
      // }catch(e){
      //   console.log(e);
      //   setEditorState(EditorState.createEmpty());
      // }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  const WrapHtmlIntoSingleElementIfNeeded = (html:string) => {
    const doc = new DOMParser().parseFromString(html||'', 'text/html').body.childNodes;
    let nodes = Array.from(doc);
    nodes = nodes.filter(x=>x.nodeName !== '#text');
    console.log(nodes);

    if(nodes.length > 1){
      console.log('More than one element - Wrapped it');
      return `<div>${html}</div>`;
    };
    return html;    
    
  };

  const onEditorStateChanged = (state) => {
    setEditorState(state);
    if(!props.saveBeforeChange){
      props.changed(toHtml(state));
    }
  };

  const onBlur = (event, editorState)=>{
    if(!props.saveBeforeChange && props.onblur) props.onblur();

  };

  const savePress = ()=>{
    let val = toHtml(editorState);
    props.changed(val);    
    if(props.onblur) props.onblur(val);
    console.log('Really...');
  };
  const closePress = ()=>{
    if(props.close) props.close();
  };
  const closeSavePress = ()=>{
    savePress();
    closePress();
  };

  const toHtml = (state) => {
    return draftToHtml(convertToRaw(state.getCurrentContent()));
  };

  const getOptions = () => {

    switch (props.options) {
      case "full":
        return {
        options: ["inline",
        "blockType",
        //"fontSize",
        // "fontFamily",
        "list",
        "link",
        "textAlign",
        "image",
        "colorPicker",
        "remove",
        "history",        
      ],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
        list: {options: ['unordered', 'ordered'], inDropdown:true},
        textAlign: {inDropdown:true, options: ['left', 'center', 'right']},
        };
        break;

      case "simple":
        return {
          options: ["inline",
          // "fontSize",
          "list",
          "textAlign",
          //"image",
        ],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          fontSize: {
          },
          list: {options: ['unordered', 'ordered'], inDropdown:true},
          textAlign: {inDropdown:true, options: ['left', 'center', 'right']}
          };
        break;
    }
  };

  const getHeightClass = ()=>{
    if(props.height === 'short'){
      return 'editor-short';
    }
    return '';
  }

  return (
    <>
    <div className={expanded ? 'wysiwg-expanded':''} onMouseDown={()=>{setExpanded(false)}}>
      <div className="wysiwg-container" onMouseDown={(e)=>{e.stopPropagation();}}>
      <Wysiwyg
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName={`editor-class ${getHeightClass()}`}
        toolbarClassName="toolbar-class"
        toolbar={getOptions()}
        //toolbarCustomButtons={props.options === 'full' ? [<ImagePro />] : []}
        onTab={(event) => {
          event.cancelBubble = true;
        }}
        onEditorStateChange={onEditorStateChanged}
        onBlur={onBlur}
        readOnly={props.readOnly ?? false}
      />
      {!props.hideExpand && <>
      {!expanded && <span className="click" onClick={()=>{setExpanded(true)}}><Icon type={IconType.expand}/> Expand</span>}
      {expanded && <span className="click" onClick={()=>{setExpanded(false)}}><Icon type={IconType.expandDown}/> Collapse</span>}
      </>}
      {props.saveBeforeChange && <button type="button" className="btn btn-secondary" onClick={savePress}><Icon type={IconType.save}/> Save </button>}
      {props.close && props.saveBeforeChange && <button type="button" className="btn btn-default" onClick={closeSavePress}><Icon type={IconType.save}/> Save & Close </button>}
      {props.close && <button type="button" className="btn btn-default" onClick={closePress}><Icon type={IconType.close}/> Close </button>}
      </div>
      </div>
    </>
  );
};

export default Editor;

// export class ImagePro extends Component {
//   state = {
//     showModal:false,
//   }
//   static propTypes = {
//     onChange: PropTypes.func,
//     editorState: PropTypes.object,
//   }

//   addStar: Function = (event:MouseEvent|TouchEvent): void => {
//     const { editorState, onChange } = this.props;
//     console.log(Modifier);
//     console.log(editorState.getSelection());
//     const contentState = Modifier.replaceText(
//       editorState.getCurrentContent(),
//       editorState.getSelection(),
//       '⭐',
//       editorState.getCurrentInlineStyle(),
//     );
//     onChange(EditorState.push(editorState, contentState, 'insert-characters'));
//   };

//   valueSelected=(value)=>{
//     const { editorState, onChange } = this.props;
//     this.closeModal();
//     const html = `<img class='client-file' src='${value}'/>`;
//     const {contentBlocks, entityMap } = htmlToDraft(html);

//     const contentState = Modifier.replaceWithFragment(
//       editorState.getCurrentContent(),
//       editorState.getSelection(),
//       ContentState.createFromBlockArray(contentBlocks, entityMap ).getBlockMap(),
//     );
//     onChange(EditorState.push(editorState, contentState, 'insert-fragment'));
//   }

//   showModal=()=>{
//     this.setState({showModal:true});
//   }

//   closeModal=()=>{
//     this.setState({showModal:false});
//   }

//   render() {
//     return (
//       <>
//       <div className="rdw-history-wrapper">
//       <div className="rdw-option-wrapper" onClick={this.showModal} onTouchEnd={this.showModal}><Icon type={IconType.image}/></div>
//       </div>
//       {this.state.showModal && <Modal 
//       setModalOpen={this.closeModal} 
//       title={"Image Selector"} 
//       size="m"
//       >
//           <FilePicker fileTypes="image" valueSelected={this.valueSelected} />
//         </Modal>}
//       </>
//     );
//   }


// }

// interface IFileInfo {
//   type: "pdf" | "image" | "video" | "text" | "other";
//   path: string;
//   ext: string;
//   urlSource?: string;
// }

interface IFilePickerProps{
  fileTypes:'image'|'pdf'|'video'|'all';
  valueSelected:Function;
}

interface IfilePickerResponse {
  files:IClientFile[];
}

export interface IClientFile {
  Value: string;
  Name: string;
  Id: string;
  IsImage: boolean;
}

export const FilePicker = (props:IFilePickerProps) =>{
  const [info, setFileInfo] = useState<IFileInfo>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showOptions, setShowOptions] = useState<boolean>();
  const [files, setFiles] = useState<IClientFile[]>([]);

  useEffect(()=>{
    Init();
  },[props.fileTypes]);

  const Init = () =>{

    store.server.getApi<ServerResponse<IfilePickerResponse>>(`../file/clientFiles`)
    .then(x=>{
      if(x.Success){
      setFiles(x.Value.files);
      }
      else {
        toast.warning(x.Message);
      }
    });

  };
  const selectExistingFile= (x:IClientFile)=>{
    let fi = getFileInfo(x.Value);
    if(props.valueSelected) props.valueSelected(x.Value);
    setFileInfo(fi ?? undefined);
    setShowOptions(false);
  }

  const removeFile = (file:IClientFile) =>{
    if(window.confirm(`Are you sure you want to remove this "${file.Name}" file?`)){
      store.server.postApi<ServerResponse<IfilePickerResponse>>(`../File/RemoveClientFile/${file.Id}`,{})
      .then(x=>{
        if(x.Success){
          Init();
        }
        else {
          toast.warning(x.Message);
        }
      });      
    }
  };

  const onChangeFile = async(e) => {
    if (e.target.files) {
      let file = await isFileValid(e.target.files);

      if (!file) {
        e.target.files = null;
        if (fileInputRef.current) fileInputRef.current.value = "";
        return;
      }
      
      if(e.target && e.target.value)
      e.target.value = null;

      if(fileInputRef.current)
        fileInputRef.current.value = '';

      store.server.postApi<ServerResponse<IfilePickerResponse>>(`../File/SaveClientFile`,{},[file.file])
      .then(x=>{
        if(x.Success){
          Init();
        }
        else {
          toast.warning(x.Message);
        }
      });
      
      setFileInfo(file.fileInfo);
      setShowOptions(false);
    }
  };

  const isFileValid = async (files: FileList) => {
    if (!files) return;
    let file = files[0];

    let size = file.size / 1024 / 1024;
    let name = file.name;
    let fileInfo = getFileInfo(file.name);
    if (!fileInfo) return null;

    if (fileInfo?.type === "video" && size > 1024) {
      //to big
      toast.warning(
        `The file "${name}" is too large. The max file size is 1 GB`
      );
      return null;
    } else if (fileInfo?.type === "pdf" && size > 50) {
      //to big
      toast.warning(
        `The file "${name}" is too large. The max file size is 50 MB`
      );
      return null;
    } else if (size > 20) {
      //to big
      toast.warning(
        `The file "${name}" is too large. The max file size is 20 MB`
      );
      return null;
    }
    
    fileInfo.urlSource = await readURL(file);

    return { file, fileInfo };
  };

  const getFileInfo = (filePath: string | undefined) => {
    if (!filePath || typeof(filePath) !== typeof(' ')) return null;
    
    let ext = filePath.substring(filePath.lastIndexOf(".")).toLowerCase();
    let isPdf = ext === ".pdf";
    let isImage =
      ext === ".jpg" ||
      ext === ".jpeg" ||
      ext === ".png" ||
      ext === ".tif" ||
      ext === ".tiff" ||
      ext === ".gif";
    let isVideo = [".mov", ".mp4"].find((x) => x === ext);
    let isText = [".csv", ".xml", ".txt"].find((x) => x === ext);
    let type = isPdf
      ? "pdf"
      : isImage
      ? "image"
      : isVideo
      ? "video"
      : isText
      ? "text"
      : "other";

    let result: IFileInfo = { type: type, path: filePath, ext: ext };
    return result;
  };

  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const nameOrFile = (x: UiValuePairs) => {
    let file = getFileInfo(x.Value);
    if (file && file.path) {
      switch (file.type) {
        case "image":
          return <div className="flex-between">
            <div className="thumbnail-1 click"><EasyImage src={file.path}  /></div>
            {/* <div className="thumbnail-1 click"><EasyImage imageSrc={[{alt:x.Name, path:file.path}]} name={x.Name} onClick={()=>{selectExistingFile(x)}}  /></div> */}
          </div>
        case "pdf":
          return <div>
             <a href={file.path}><Icon type={IconType.filePdf} /> {x.Name}</a>
          </div>

        case "video":
          return <div>
             <a href={file.path} target={"_blank"}><Icon type={IconType.video} /> {x.Name}</a>
          </div>
        case "text":
        case "other":
         return <div>
            <a href={file.path} target={"_blank"}><Icon type={IconType.fileInvoice} /> {x.Name}</a>
         </div>
          break;
      }
    }
    return <strong>{x.Name}</strong>;
  };

  return (<>
        <div className="file-picker existing-file-container">
          <div className="images">
          {files.filter(x=>x.IsImage).map((x,i)=>{
          return <div key={`efo-${i}`} className="existing-file-item">
            {nameOrFile(x)} 
            <span className="remove text-danger" onClick={()=>{removeFile(x)}}><Icon type={IconType.trashO} /></span>
          </div>
        })}
          {files.filter(x=>!x.IsImage).map((x,i)=>{
          return <div key={`efo-${i}`} className="existing-file-item">
            {nameOrFile(x)} 
            <span className="remove text-danger" onClick={()=>{removeFile(x)}}><Icon type={IconType.trashO} /></span>
          </div>
        })}
          </div>


          
        <div className="addmore">
          <h4>Upload File</h4>
          <input
            type="file"
            className="form-control"
            ref={fileInputRef}
            onChange={onChangeFile}
            accept={'.pdf,.jpg,.txt,.xml,.docx,.doc,.xlsx,.xls,.png,.gif,.mp4,.jpeg,.csv,.ppt,.pptx'}
          />
          </div>


        </div>
  </>);

}; 