import React, { useState, useEffect } from 'react';
import StoreX, { store } from '../../redux/oldStore';
import Icon, { IconType } from '../Icon/Icon';
import redux, { IState, ReduxMap } from '../../redux/redux';

interface IProps extends IState {
  useOld: boolean;
}

const Navigation = (props: IProps) => {
  const [prefix, setPrefix] = useState<string>();

  useEffect(() => {
    setPrefix(props.SettingsResponse?.Url ?? `${window.location.protocol}//${window.location.host}`);
  }, [props.SettingsResponse]);

  const toggleEdit = () => {
    redux.toggelEdit(props.ShowEdit ? false : true);
  };

  const logout = () => {
    redux.NotReady();
    window.location = setUrl('../logout.aspx');
  };

  const setUrl = (url) => {
    let u = url.replace('../', props.SettingsResponse?.Url);
    if (u.indexOf('&f=') > -1 || u.indexOf('?f=') > -1) {
      return u;
    } else if (u.indexOf('?') > -1) {
      return u + '&f=' + props.Settings?.fairId;
    }
    return u + '?f=' + props.Settings?.fairId;
  };

  const setUrlFromSource = (url) => {
    let u = url.replace('../', props.SettingsResponse?.Url);
    return u;
  };

  const oldMenu = () =>{
    return <>
    <nav className="navbar navbar-default">
      <div>
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand visible-xs" href="#">
            {props.SettingsResponse?.Name ?? 'zFairs'}
          </a>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav">
            {props.SettingsResponse?.Nav?.map((x, i) => {
              const hasSubs = x.subNav;

              return (
                <li key={i}>
                  {!hasSubs && (
                    <a href={setUrlFromSource(x.href)} target={x.newTab ? '_blank' : ''}>
                      <span dangerouslySetInnerHTML={{ __html: x.iconHtml }}></span>
                      {x.name}
                    </a>
                  )}

                  {hasSubs && (
                    <a href={setUrlFromSource(x.href)} target={x.newTab ? '_blank' : ''} className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      <span dangerouslySetInnerHTML={{ __html: x.iconHtml }}></span>
                      {x.name} <span className="caret"></span>
                    </a>
                  )}

                  {x.subNav && (
                    <ul className="dropdown-menu">
                      {x.subNav?.map((xx, xi) => (
                        <li key={xi}>
                          <a key={xi} href={setUrlFromSource(xx.href)} target={xx.newTab ? '_blank' : ''}>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: xx.iconHtml,
                              }}></span>
                            {xx.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <ul className="nav navbar-nav navbar-right">
            {props.User?.signedIn ? (
              <>
                {props.User.Admin && (
                  <li>
                    <a
                      href={'#'}
                      onClick={() => {
                        toggleEdit();
                      }}
                      className={`${props.ShowEdit ? 'strong' : ''}`}>
                      <Icon type={IconType.edit} />
                      Edit
                    </a>
                  </li>
                )}
                <li>
                  {' '}
                  <a className="dropdown-toggle nav-user-config" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    {' '}
                    <i className="fal fa-user"></i> {props.User.firstName} <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    {props.SettingsResponse?.loggedInAsUserFrom && (
                      <li>
                        <a href={store.server.makeUrl(`../person/BackToUser/${props.SettingsResponse.loggedInAsUserFrom}`)} target={''}>
                          <i className="fa fa-user-secret"></i>&nbsp;Back to Account
                        </a>
                      </li>
                    )}
                    <li>
                      <a href={setUrl('../app/user/profile')} target={''}>
                        <i className="fa fa-user"></i> My Profile
                      </a>
                    </li>
                    <li>
                      <a href={setUrl('../app/user/password')} target={''}>
                        <i className="fa fa-unlock-alt"></i> Change Password
                      </a>
                    </li>

                    <li>
                      <a onClick={logout} target={''}>
                        <i className="fa fa-sign-out"></i> Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <li>
                <a
                  onClick={() => {
                    redux.showLogin(true);
                  }}
                  className="nav-login-button">
                  <i className="fa fa-sign-in"></i> {props.Settings?.text?.NavLogin ?? 'Sign-in'}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  </>
  };

  const newMenu = () =>{
    return <>
    <nav className="navbar navbar-default">
      <div>
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand visible-xs" href={StoreX.BuildUrl("../")}>
            {props.SettingsResponse?.Name ?? 'zFairs'}
          </a>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            {props.SettingsResponse?.Nav?.map((x, i) => {
              const hasSubs = x.subNav && x.subNav.length > 0;

              return (
                <li key={i}>
                  {!hasSubs && (
                    <a href={setUrlFromSource(x.href)} target={x.newTab ? '_blank' : ''}>
                      {/* <span dangerouslySetInnerHTML={{ __html: x.iconHtml }}></span> */}
                      {x.name}
                    </a>
                  )}

                  {hasSubs && (
                    <a href={setUrlFromSource(x.href)} target={x.newTab ? '_blank' : ''} className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                      {/* <span dangerouslySetInnerHTML={{ __html: x.iconHtml }}></span> */}
                      {x.name} <span className="caret"></span>
                    </a>
                  )}

                  {x.subNav && (
                    <ul className="dropdown-menu">
                      {x.subNav?.map((xx, xi) => (
                        <li key={xi}>
                          <a key={xi} href={setUrlFromSource(xx.href)} target={xx.newTab ? '_blank' : ''}>
                            {/* <span
                              dangerouslySetInnerHTML={{
                                __html: xx.iconHtml,
                              }}></span> */}
                            {xx.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
            {props.User?.signedIn ? (
              <>
                <li>
                  {' '}
                  <a className="dropdown-toggle nav-user-config" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                    {' '}
                    {/* <i className="fal fa-user"></i>  */}
                    {props.User.firstName} <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    {props.SettingsResponse?.loggedInAsUserFrom && (
                      <li>
                        <a href={store.server.makeUrl(`../person/BackToUser/${props.SettingsResponse.loggedInAsUserFrom}`)} target={''}>
                          {/* <i className="fa fa-user-secret"></i>&nbsp; */}
                          Back to Account
                        </a>
                      </li>
                    )}
                    <li>
                      <a href={setUrl('../app/user/profile')} target={''}>
                        {/* <i className="fa fa-user"></i>  */}
                        My Profile
                      </a>
                    </li>
                    <li>
                      <a href={setUrl('../app/user/password')} target={''}>
                        {/* <i className="fa fa-unlock-alt"></i>  */}
                        Change Password
                      </a>
                    </li>

                    <li>
                      <a onClick={logout} target={''}>
                        {/* <i className="fa fa-sign-out"></i>  */}
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <li>
                <a
                  onClick={() => {
                    redux.showLogin(true);
                  }}
                  className="nav-login-button">
                  <i className="fa fa-sign-in"></i> {props.Settings?.text?.NavLogin ?? 'Sign-in'}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  </>
  };

  return (
    <>
    {props.useOld ? <>{oldMenu()}</>
    : <>{newMenu()}</>}
    </>
  );
};

export default ReduxMap(Navigation);
