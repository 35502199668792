import React, {useState, useEffect, useCallback} from 'react';
import { Server, ServerResponse } from '../../utils/Server';
import Icon, { IconType } from "../Icon/Icon";
import { IFormGroup, IPacketsInfo } from './FormTypes';
import { v4 as uid } from "uuid";
import PacketBuilder from './PacketBuilder';
import StoreX from '../../redux/oldStore';
import { toast } from 'react-toastify';
import Button from '../_Core/Button';

interface IGroupManagerProps {

}

const GroupManager = (props:IGroupManagerProps) =>{
    const [data, setData] = useState<IPacketsInfo>();
    const [selectedPacket, setSelectedPacket] = useState<IFormGroup>();

    useEffect(()=>{
        StoreX.instance.server.postApi<ServerResponse<IPacketsInfo>>(`../GenericForms/Packets`, {})
        .then(x=>{
            if(x.Success){
                setData(x.Value);
            }else {
                toast.error(`Error getting your packets: ${x.Message}`);
            }
        });
    },[]);

    const toggleInUse = (packet:IFormGroup)=>{
        toast.dismiss();
        let request = {use:!packet.InUse, packetId:packet.PublicId};
        StoreX.instance.server.postApi<ServerResponse<IFormGroup>>(`../GenericForms/UsePacket`, request)
        .then(x=>{
            if(x.Success){
                if(data){
                let ps = [...data.Packets];
                for(let i = 0; i < ps.length; i++){
                    if(ps[i].PublicId === x.Value.PublicId){
                        ps[i]= x.Value;
                        break;
                    }
                }
                setData({...data, Packets:ps});
            }
                toast.success(`Packet ${x.Value.Name} is now ${x.Value.InUse?'in use':'not in use'}`);
            }else {
                toast.error(`Error toggling in use: ${x.Message}`);
            }
        });
    };


    const mapFormPackets = ()=>{
        if(!data?.Packets || data.Packets.length === 0){
            return <>Currently you don't have any packets defined. Click on <strong><Icon type={IconType.plus}/> New Packet</strong> to define a new packet.</>;
        }

        return <>
        <div className="packet-tile-container">
            {data.Packets.map((x, i)=>{
                return <div key={`packet-key-${i}`} className="packet-tile">
                    <div className='packet-bottom-banner'>
                        {x.IsShared && <div className="shared-packet">Default Form Package</div>}
                        <div className={`packet-in-use-${x.InUse?'true':'false'} text-right grow`}>
                            <Button extraSmall={true} type={x.InUse ? 'on':'off'} onClick={()=>{
                                toggleInUse(x);
                            }} text={x.InUse?'In Use':'Not In Use'}/>
                        </div>
                    </div>

                    <div className="flex-between">
                    <h4>{x.Name}</h4>
                    <button type="button" className="btn btn-default" onClick={()=>{setSelectedPacket(x)}}>
                        <Icon type={IconType.edit} /> Edit
                    </button>
                    </div>
                    <div><Icon type={IconType.filePdf}/> Forms: {x.Forms.length}</div>
                    <div className="packet-body">
                        {x.Description && <div dangerouslySetInnerHTML={{__html:x.Description}} />}
                        </div>

                    
                </div>
            })}
            </div>
        </>

    };

    const addNewPacket = ()=>{
        setSelectedPacket({Name:'', Description:'',PublicId:uid(),Forms:[]});
    };
    const packetUpdated = (packet:IFormGroup) =>{
        setSelectedPacket(packet);
        if(data){
        let ps = [...data.Packets];
        let found = false;
        for(let i = 0; i < ps.length; i++){
            if(ps[i].PublicId === packet.PublicId){
                ps[i]= packet;
                found = true;
                break;
            }
        }
        if(!found) ps.push(packet);
        setData({...data, Packets:ps});
    }
    };

    return (<>
    <div className="col-sm-12">
        {!selectedPacket && <div>
            <div className="flex-between">
                <h3>Forms Manager</h3>
                <div>
                    <button type="button" className="btn btn-secondary" onClick={addNewPacket}>
                        <Icon type={IconType.plus} /> New Packet
                    </button>
                </div>                
            </div>
            Below are listed your form packets, these are packets of forms 
            you can have your participants fill out.
            <hr/>

            <div>
                {mapFormPackets()}
            </div>
        </div>}
        {selectedPacket && data && <div>
            <div className="flex-between">
                <h4>Edit Packet: <strong>{selectedPacket.Name || 'Untitled'}</strong></h4>
                    <button type="button" className="btn btn-default" onClick={()=>{setSelectedPacket(undefined)}}>
                        <Icon type={IconType.close} /> Close Packet
                    </button>
            </div>
            <div>
                <PacketBuilder 
                        packet={selectedPacket}
                        update={packetUpdated}
                        close={() => { setSelectedPacket(undefined); } } 
                        divisions={data.Divisions} 
                        categories={data.Categories} 
                        entryTypes={data.EntryTypes}               
                     />
            </div>
            </div>}
        </div>
    </>);
};

export default GroupManager;