import { RouteComponentProps } from 'react-router-dom';
import { IState, ReduxMap } from '../../redux/redux';
import { useEffect, useState } from 'react';
import StoreX, { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { toast } from 'react-toastify';
import Button from '../_Core/Button';
import Icon, { IconType } from '../Icon/Icon';
import { IListItem } from '../_Core/CoreTypes';
import { ISchoolAffiliateInfo, ISchoolInfo } from './schoolTypes';
import SchoolAffiliation from './SchoolAffiliation';

interface IProps extends IState, RouteComponentProps<IMatchParams> {}

interface IMatchParams {
  schoolId?: string;
}


const SchoolAffiliateManager = (props: IProps) => {
  const [schoolId, setSchoolId] = useState<number>(+(props.match.params.schoolId + ''));
  const [data, setData] = useState<ISchoolInfo>();
  const [show, setShow] = useState<'list' | 'edit' | 'dont'>('list');
  const [divisionDic, setDivisionDic] = useState<Record<number, string>>({});
  const [form, setForm] = useState<ISchoolAffiliateInfo>({});
  const [seasonDic, setSeasonDic] = useState<Record<number, string>>({});

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    store.server.getApi<ServerResponse<ISchoolInfo>>(`../SchoolAffiliate/info/${schoolId}`).then((x) => {
      if (x.Success) {
        setData(x.Value);
        let dic = {};
        x.Value.divisions?.forEach((r) => {
          dic[+r.id] = r.name;
        });
        setDivisionDic(dic);

        let seaDic = {};
        x.Value.seasons?.forEach((r)=>{seaDic[r.id]=r.name;});
        setSeasonDic(seaDic);
      } else {
        toast.error(x.Message);
      }
    });
  };


  return (
    <>
      {data && show === 'list' && (
        <div>
          <div className="flex-between">
            <div>
              <h2>{data.name}</h2>
              <h4>School Affiliate Manager</h4>
            </div>
            <div>
              <Button type="refresh" onClick={init} />
              <Button
                type="new"
                text="New School Affiliation"
                onClick={() => {
                  setShow('edit');
                  setForm({SchoolId:schoolId});
                }}
              />
            </div>
          </div>
          <hr />
          {data.affiliates && data.affiliates.length > 0 ? (
            <>
                <div className='school-affiliate-list'>
                    {data.affiliates.map((x,i)=>{
                        return <div key={i} className='list-item flex-between'>
                            <div className='flex'>
                            <div className='space'>
                            <Button type='edit' iconOnly={true} onClick={()=>{
                                    setForm(x);
                                    setShow('edit');
                                }} />
                            </div>
                            <div>
                                {x.Division && <h4>{divisionDic[x.Division ?? '']}</h4>}
                                {x.Season && <div>{x.Season}</div>}
                                <a href={StoreX.BuildUrlWithFair(`../app/school/affiliate/record/${x.PublicId}`)} target='_blank'>Affiliate Page</a>
                            </div>
                            </div>
                            <div className='text-right'>
                               {x.Status && <div className='big'><Icon type={IconType.checkCircle}/> {x.Status}</div>}
                               {x.InvoiceId && <div><a href={StoreX.BuildUrlWithFair(`../purchase/getinvoice/${x.InvoiceId}`)} target='_blank'><Icon type={IconType.invoice}/> Invoice</a></div>}
                            </div>
                        </div>
                    })}
                </div>
            </>
          ) : (
            <>
              <div className="alert alert-info">
                <h2>
                  <Icon type={IconType.warning} /> No Records
                </h2>
                No School Affiliation Records Were Found.
              </div>
            </>
          )}
        </div>
      )}
 {data && show === 'edit' && (<>
    <SchoolAffiliation
                  data={data}
                  state={props}
                  onSaved={(x) => { init(); setShow('list'); } }
                  onRemoved={(x) => { init(); setShow('list'); } }
                  onCanceled={data.isStaff ? () => { setShow('list'); } : undefined} 
                  record={form}        />
 </>)}
    </>
  );
};

export default ReduxMap(SchoolAffiliateManager);
