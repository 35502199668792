import { Switch, Route } from 'react-router-dom';
import Showcase from './Components/Showcase/Showcase'
import ParticipantPaperworkReivew from './Components/Paperwork/ParticipantPaperworkReview'
import JudgingSetupContainer from './Components/JudgingSetup/JudgingSetupContainer';
import DisplayAndSafety from './Components/DisplayAndSafety/DisplayAndSafety';
import Biliteracy from './Components/Biliteracy/Biliteracy';
import Setup from './Components/Biliteracy/Setup';
import Load from './Components/Biliteracy/Load';
import ParticipantFileManager from './Components/ParticipantFileManagement/ParticipantFileManager';
import ExternalJudgesContainer from './Components/ExternalJudges/ExternalJudgesContainer'
import ParticipantVideoContainer from './Components/ParticipantVideo/ParticipantVideoContainer'
import PdfConverter from './Components/PdfConversion/PdfConverter';
import { toast } from 'react-toastify';
import StudentReviewPortal from './Components/OnlineReview/StudentReviewPortal';
import AdultReviewPortal from './Components/OnlineReview/AdultReviewPortal';
import AcaDecaContainer from './Components/Client/AcaDeca/AcaDecaContainer';
import SpellingBeeContainer from './Components/SpellingBee/SpellingBeeContainer';
import SuperQuizContainer from './Components/SuperQuiz/SuperQuizContainer';
import JudgingResultsContainer from './Components/JudgingResults/JudgingResultsContainer';
import EmailToolContainer from './Components/EmailTool/EmailToolContainer';
import NotificationHubContainer from './Components/NotificationHub/NotificationHubContainer';
import JustVideoContainer from './Components/JustVideo/JustVideoContainer';
import FairPromotionLinkingContainer from './Components/FairPromotionLinking/FairPromotionLinkingContainer';
import Dashboard from './Components/JudgingDashboard/Dashboard';
import TagsContainer from './Components/Tags/TagsContainer';
import Import from './Components/Import/Import';
import CirclesScheduler from './Components/Circles/CirclesScheduler';
import MyAwards from './Components/My/Awards';
import AdvancedJudgingLanding from './Components/AdvancedJudging/AdvancedJudgingLanding';
import ChatPage from './Components/SupportChat/ChatPage';
import QuestionCreator from './Components/QuestionCreation/QuestionCreator';
import RubricSetup from './Components/JudgingSetup/RubricSetup/RubricSetup';
import FormFromApiData from './Components/FormFromApiData/FormFromApiData';
import UserProjectDisplay from './Components/UserProject/UserProjectDisplay';
import PeoplePage from './Components/PeoplePage/PeoplePage';
import ChangePasswordForm from './Components/PeoplePage/ChangePasswordForm';
import ParticipantRequirements from './Components/UserRequirements/ParticipantRequirements';
import StoreFront from './Components/Store/StoreFront';
import UserRegistrationComplete from './Components/User/UserRegistrationComplete';
import ConfirmEmail from './Components/FormFromApiData/ConfirmEmail';
import FirstTimeLoginComplete from './Components/User/FirstTimeLoginComplete';
import GenericFormsLanding from './Components/GenericForms/GenericFormsLanding';
import GroupManager from './Components/GenericForms/GroupManager';
import FloorPlanner from './Components/FloorPlanner/FloorPlanner';
import SetupAll from './Components/Setup/SetupAll';
import LicenseExpired from './Components/Error/LicenseExpired';
import SetupStart from './Components/Setup/SetupStart';
import ExamLanding from './Components/Exam/ExamLanding';
import ExamTest from './Components/Exam/ExamTest';
import ExamDashboard from './Components/Exam/ExamDashboard';
import Credits from './Components/Credits';
import { InvoiceMangement } from './Components/Store/InvoiceManagement';
import ProjectDetailsWraper from './Components/Showcase/projectDetailsWraper';
import CategoryCaptainManager from './Components/SetupTools/CategoryCaptain/CategoryCaptainManager';
import EventSessionManager from './Components/SetupTools/EventSessions/EventSessionManager';
import WaitingRoom from './Components/AdvancedJudging/WaitingRoom';
import BranchContainer from './Components/Setup/Branches/BranchContainer';
import Checkin from './Components/Checkin/Checkin';
import ConfigFormLanding from './Components/Setup/ConfigForm/ConfigFormLanding';
import BasePageContent from './Components/Page/BasePageContent';
import { HtmlEditorPro2 } from './Components/Tools/htmlEditor2';
import Invoices from './Components/Organization/Invoices';
import Invoice from './Components/Organization/Invoice';
import OldSettingsLayout from './Components/Setup/OldSettingsLayout/OldSettingsLayout';
import { AssignSpecialAwards } from './Components/Management/Awards/AssignSpecialAwards';
import { ReportViewer } from './Components/Reports/Report';
import OutsideBranch from './Components/Setup/Branches/OutsideBranch';
import AssignJudgesToCategoryWizard from './Components/SetupTools/CategoryCaptain/AssignJudgesToCategoryWizard';
import Medical from './Pages/Users/Medical';
import NhdScheduleAndJudging from './Pages/Client/NHD/NhdScheduleAndJudging';
import NhdSchedule from './Pages/Client/NHD/NhdSchedule';
import JudgingCirclesMain from './Pages/Judging/JudgingCirclesMain';
import FlexFlowManagerPage from './Pages/Flex/Setup/FlexFlowManagerPage';
import FlexFlowPage from './Pages/Flex/FlexFlowPage';
import FlexFlowDonePage from './Pages/Flex/FlexFlowDonePage';
import JudgingFeedback from './Components/JudgingResults/JudgingFeedback';
import FlexFlowFeePage from './Pages/Flex/FlexFlowFeePage';
import FlexViewer from './Components/FlexFlow/FlexViewer';
import ExternalLanding from './Pages/External/ExternalLanding';
import SchoolAffiliateManager from './Components/School/SchoolAffiliateManager';
import SchoolAffilateForm from './Components/School/SchoolAffilateForm';
import NewUserLanding from './Components/User/NewUserLanding';



type settings = any;

type routesProps = {
  settings: settings
}

const uploadFileHook = (pdfUrl: string) => {
  toast.info(pdfUrl);
}

export default (
  <Switch>
    <Route path="/app/reivewportal/student" component={StudentReviewPortal} />
    <Route path="/app/reivewportal/adult" component={AdultReviewPortal} />

    <Route path='/app/showcase/entry/:projectKey' render={()=> <ProjectDetailsWraper />} />
    <Route path='/app/showcase/:projectKey' render={()=> <ProjectDetailsWraper />} />
    <Route path="/app/showcase" component={Showcase} />
    <Route path='/app/paperwork/participant' component={ParticipantPaperworkReivew} />

    <Route path='/app/judging/setup/rubric' component={RubricSetup} />
    <Route path='/app/judging/setup' component={JudgingSetupContainer} />
    <Route path='/app/app/judging/setup' component={JudgingSetupContainer} />
    <Route path='/app/judging/results' component={JudgingResultsContainer} />
    <Route path='/app/judging/dashboard' component={Dashboard} />

    <Route path='/app/displayandsafety' component={DisplayAndSafety} />

    <Route path='/app/Biliteracy/Setup' component={Setup} />
    <Route path='/app/Biliteracy/Load' component={Load} />
    <Route path='/app/Biliteracy' component={Biliteracy} />

    <Route path='/app/externaljudges' component={ExternalJudgesContainer} />

    <Route path='/app/video/participant' component={ParticipantVideoContainer} />
    <Route path='/app/video/:roomId' component={JustVideoContainer} />
    <Route path='/app/CirclesScheduler/:roundId' component={CirclesScheduler} />

    <Route path='/app/AdvancedJudging/:userId' component={AdvancedJudgingLanding} />
    <Route path='/app/acadeca' component={AcaDecaContainer} />
    <Route path='/app/app/acadeca' component={AcaDecaContainer} />

    <Route path='/app/SupportChat' component={ChatPage} />
    <Route path='/app/My/Awards' component={MyAwards} />

    <Route path='/app/spellingbee' component={SpellingBeeContainer} />
    <Route path='/app/superquiz' component={SuperQuizContainer} />
    <Route path='/app/emailtool' component={EmailToolContainer} />
    <Route path='/app/notifications' component={NotificationHubContainer} />
    <Route path='/app/fair/promotionlinking' component={FairPromotionLinkingContainer} />
    <Route path='/app/tags' component={TagsContainer} />
    <Route path='/app/import' component={Import} />
    <Route path='/app/files' render={() => (<ParticipantFileManager isNew={false} />)} /> {/*Render accepts a funcional component and that function won't get unnecessarily remonuted like with component=*/}
    <Route path='/app/pdf-convert' render={() => (<PdfConverter uploadFileHook={uploadFileHook} />)} />
    <Route path='/app/question-creator' component={QuestionCreator}></Route>

    <Route path='/app/WaitingRoom/:userId' render={()=> <WaitingRoom />} />

   <Route
      path='/app/user/profile/:targetId/:roleName'
      render={() =>
        <FormFromApiData
          idCalledInApi='userId'
          getInfoRoute='../UserProfile/MainForm'
          saveRoute='../UserProfile/SaveForm'
          isNew={false}
        />
      }
    /> 

<Route path='/app/school/affiliate/record/:affiliateId' component={SchoolAffilateForm}/>
<Route path='/app/school/affiliate/record' component={SchoolAffilateForm}/>

<Route path='/app/school/affiliate/:schoolId' component={SchoolAffiliateManager}/>

    <Route path='/app/JudgingCircles' render={()=><JudgingCirclesMain />}/>
    <Route path='/app/JudgingFeedback' render={()=><JudgingFeedback />}/>


    <Route path='/app/client/nhd/scheduleAndJudging' render={()=><NhdScheduleAndJudging />}/>
    <Route path='/app/client/nhd/schedule' render={()=><NhdSchedule />}/>
    <Route path='/app/client/nhd/scheduling' render={()=><NhdSchedule />}/>


    <Route path='/app/user/email/:targetId/' render={()=><ConfirmEmail />}/>
    <Route path='/app/user/profile/:targetId/' render={() => <FormFromApiData isNew={false} idCalledInApi='userId' getInfoRoute='../UserProfile/MainForm' saveRoute='../UserProfile/SaveForm'/>} />
  
    <Route path='/app/user/new/:roleName/:targetId/' render={() => <FormFromApiData isNew={true} idCalledInApi='userId' getInfoRoute='../UserProfile/MainForm' saveRoute='../UserProfile/SaveForm'/>} />
    <Route path='/app/user/new/:roleName/' render={() => <FormFromApiData isNew={true} idCalledInApi='userId' getInfoRoute='../UserProfile/MainForm' saveRoute='../UserProfile/SaveForm'/>} />
    <Route path='/app/user/new' component={NewUserLanding}/>
    
    <Route path='/app/user/firstTimeLogin/Done/:targetId/' render={() => <FirstTimeLoginComplete Type={'FirstTime'} />}/>
    <Route path='/app/user/Update/Done/:targetId/' render={() => <FirstTimeLoginComplete Type={'ProfileUpdate'} />}/>
    <Route path='/app/user/firstTimeLogin/:targetId' render={() => <FormFromApiData isNew={false} idCalledInApi='userId' getInfoRoute='../UserProfile/MainForm' saveRoute='../UserProfile/SaveForm'/>} />

    <Route path='/app/user/password' render={() => <ChangePasswordForm isSelfPasswordChange={true} />} />
    <Route path='/app/user/profile/' render={() => <FormFromApiData isNew={false} idCalledInApi='userId' getInfoRoute='../UserProfile/MainForm' saveRoute='../UserProfile/SaveForm'/>}/>
    
    <Route path='/app/project/:userId/:targetId/:entryType' render={()=> <UserProjectDisplay AlwaysShow={false} UserId={undefined}/>} />
    <Route path='/app/project/:userId/:targetId' render={()=> <UserProjectDisplay AlwaysShow={false} UserId={undefined}/>} />
    <Route path='/app/project/:userId' render={()=> <UserProjectDisplay AlwaysShow={false}  UserId={undefined}/>} />
    <Route path='/app/projects/:userId' render={()=> <UserProjectDisplay AlwaysShow={true}  UserId={undefined}/>} />

    <Route path='/app/people' component={PeoplePage} />
    <Route path='/app/user/requirements/:userId' component={ParticipantRequirements} />
    <Route path='/app/user/medical/:userId' component={Medical} />
    <Route path='/app/user/done/:userId/:projectId' component={UserRegistrationComplete} />
    <Route path='/app/user/done/:userId' component={UserRegistrationComplete} />


    <Route path ='/app/store/invoicemanagement' component={InvoiceMangement} />
    <Route path ='/app/store/invoice/:invoice' component={StoreFront} />
    <Route path ='/app/store/:userId' component={StoreFront} />
    <Route path ='/app/store' component={StoreFront} />

    <Route path='/app/form/manager/' render={()=> <GroupManager />} />
    <Route path='/app/form/group/:groupId' render={()=> <GenericFormsLanding />} />
    
    <Route path='/app/form/sign/:groupId/:projectId/:signerId' render={()=> <GenericFormsLanding isSign={true} />} />

    <Route path='/app/floorplanner' component={FloorPlanner} />


    <Route path='/app/organization/invoices' component={()=><Invoices />}/>
    <Route path='/app/organization/invoice/:invoiceId' component={()=><Invoice />}/>
    <Route path='/app/exam/test' component={()=><ExamTest />}/>
    <Route path='/app/exam/dashboard' component={()=><ExamDashboard />}/>
    <Route path='/app/exam' component={()=><ExamLanding />}/>
    
    <Route path='/app/setup/old' component={OldSettingsLayout} />
    
    
    <Route path='/app/setup/xxx' component={()=><ConfigFormLanding type='xxx' />} />
    <Route path='/app/setup/photo-booth-obj' component={()=><ConfigFormLanding type='photo-booth-obj' />} />
    
    <Route path='/app/setup/participants-removed' component={()=><ConfigFormLanding type='participants-removed' />} />


    <Route path='/app/flex/Viewer' component={()=><FlexViewer />} />
    <Route path='/app/flex/done/:flowId/:recordId' component={()=><FlexFlowDonePage />} />
    <Route path='/app/flex/fee/:flowId/:recordId' component={()=><FlexFlowFeePage />} />
    <Route path='/app/flex/:flowId/:recordId' component={()=><FlexFlowPage />} />
    <Route path='/app/flex/:flowId' component={()=><FlexFlowPage />} />
    <Route path='/app/setup/flex/:flowId' component={()=><FlexFlowManagerPage />} />

    <Route path='/app/setup/affiliate' component={()=><ConfigFormLanding type='affiliate' />} />
    <Route path='/app/setup/fair-results' component={()=><ConfigFormLanding type='fair-results' />} />
    <Route path='/app/setup/flex-flow-instructions' component={()=><ConfigFormLanding type='flex-flow-instructions' />} />
    <Route path='/app/setup/flex-flow' component={()=><ConfigFormLanding type='flex-flow' />} />
    <Route path='/app/setup/flex-page' component={()=><ConfigFormLanding type='flex-page' />} />
    <Route path='/app/setup/flex-section' component={()=><ConfigFormLanding type='flex-section' />} />
    <Route path='/app/setup/flex-content' component={()=><ConfigFormLanding type='flex-content' />} />

    <Route path='/app/setup/invite' component={()=><ConfigFormLanding type='invite' />} />
    <Route path='/app/setup/text' component={()=><ConfigFormLanding type='text' />} />
    <Route path='/app/setup/pages' component={()=><ConfigFormLanding type='pages' />} />
    <Route path='/app/setup/page' component={()=><ConfigFormLanding type='pages' />} />
    <Route path='/app/setup/event-sessions' component={()=><ConfigFormLanding type='event-sessions' />} />
    <Route path='/app/setup/entry-types' component={()=><ConfigFormLanding type='entry-types' />} />
    <Route path='/app/setup/judging-rubric' component={()=><ConfigFormLanding type='judging-rubric' />} />
    <Route path='/app/setup/judging-rounds' component={()=><ConfigFormLanding type='judging-rounds' />} />
    <Route path='/app/setup/judging' component={()=><ConfigFormLanding type='judging' />} />
    <Route path='/app/setup/client-files' component={()=><ConfigFormLanding type='client-files' />} />
    <Route path='/app/setup/schools' component={()=><ConfigFormLanding type='schools' />} />
    <Route path='/app/setup/category-chairs' component={()=><ConfigFormLanding type='category-chairs' />} />
    <Route path='/app/setup/src-irb' component={()=><ConfigFormLanding type='src-irb' />} />
    <Route path='/app/setup/paperwork' component={()=><ConfigFormLanding type='paperwork' />} />
    <Route path='/app/setup/tags' component={()=><ConfigFormLanding type='tags' />} />
    <Route path='/app/setup/sponsors' component={()=><ConfigFormLanding type='sponsors' />} />
    <Route path='/app/setup/school-districts' component={()=><ConfigFormLanding type='school-districts' />} />
    <Route path='/app/setup/ecommerce' component={()=><ConfigFormLanding type='ecommerce' />} />
    <Route path='/app/setup/coupons' component={()=><ConfigFormLanding type='coupons' />} />
    <Route path='/app/setup/shirt-sizes' component={()=><ConfigFormLanding type='shirt-sizes' />} />
    <Route path='/app/setup/awards-special-donors' component={()=><ConfigFormLanding type='awards-special-donors' />} />
    <Route path='/app/setup/awards' component={()=><ConfigFormLanding type='awards' />} />
    <Route path='/app/setup/awards-special' component={()=><ConfigFormLanding type='awards-special' />} />
    <Route path='/app/setup/divisions' component={()=><ConfigFormLanding type='divisions' />} />
    <Route path='/app/setup/session-judge' component={()=><ConfigFormLanding type='session-judge' />} />
    <Route path='/app/setup/session-volunteer' component={()=><ConfigFormLanding type='session-volunteer' />} />
    <Route path='/app/setup/custom-questions' component={()=><ConfigFormLanding type='custom-questions' />} />
    <Route path='/app/setup/custom-questions-participant' component={()=><ConfigFormLanding type='custom-questions-participant' />} />
    <Route path='/app/setup/custom-questions-entry' component={()=><ConfigFormLanding type='custom-questions-entry' />} />
    <Route path='/app/setup/custom-questions-project' component={()=><ConfigFormLanding type='custom-questions-entry' />} />
    <Route path='/app/setup/custom-questions-teacher' component={()=><ConfigFormLanding type='custom-questions-teacher' />} />
    <Route path='/app/setup/custom-questions-judge' component={()=><ConfigFormLanding type='custom-questions-judge' />} />
    <Route path='/app/setup/custom-questions-volunteer' component={()=><ConfigFormLanding type='custom-questions-volunteer' />} />
    <Route path='/app/setup/custom-questions-attendee' component={()=><ConfigFormLanding type='custom-questions-attendee' />} />
    <Route path='/app/setup/requirements' component={()=><ConfigFormLanding type='requirements' />} />
    <Route path='/app/setup/categories' component={()=><ConfigFormLanding type='category' />} />
    <Route path='/app/setup/sub-categories' component={()=><ConfigFormLanding type='sub-category' />} />
    <Route path='/app/setup/sub-sub-categories' component={()=><ConfigFormLanding type='sub-sub-category' />} />
    <Route path='/app/setup/category' component={()=><ConfigFormLanding type='category' />} />
    <Route path='/app/setup/sub-category' component={()=><ConfigFormLanding type='sub-category' />} />
    <Route path='/app/setup/sub-sub-category' component={()=><ConfigFormLanding type='sub-sub-category' />} />
    <Route path='/app/setup/branch/test' component={()=><BranchContainer />} />
    <Route path='/app/setup/start' component={()=><SetupStart />}/>
    <Route path='/app/setup/HTML' component={()=><HtmlEditorPro2 value={''} changed={()=>{}}  />}/>
    <Route path='/app/setup/' component={SetupAll}/>

    <Route path='/app/admin/setup/eventSession' component={()=><EventSessionManager />}/>
    <Route path='/app/admin/setup/categorychair' component={()=><CategoryCaptainManager />}/>
    <Route path='/app/admin/setup/JudgesAssignCategory' component={()=><AssignJudgesToCategoryWizard />}/>
    <Route path='/app/admin/checkin' component={()=><Checkin />} />
    <Route path='/app/admin/assign-special-awards' component={()=><AssignSpecialAwards />} />


    <Route path='/app/reports/JudgingAssignmentsReportStickers' component={()=><ReportViewer type="JudgingAssignmentsReportStickers" />}/>
    
    <Route path='/app/error/license' component={()=><LicenseExpired />}/>
    <Route path='/app/credits' render={()=>Credits}/>

    
    <Route path="/app/outside" component={()=><OutsideBranch />} />


    <Route path="/app/external/landing/:type" component={()=><ExternalLanding />}/>
    <Route path="/app/external/landing" component={()=><ExternalLanding />}/>
    
    <Route path="/app/page/:pageId" component={()=><BasePageContent />} />
    <Route path="/page/:pageId" component={()=><BasePageContent />} />
    <Route path="/app/page" component={()=><BasePageContent pageId={'home'} />} />    
    <Route path="/app/home" component={()=><BasePageContent pageId='home' />} />
    <Route path="/app" component={()=><BasePageContent pageId='home' />} />
    <Route path='/' component={()=><BasePageContent pageId='home' />} />
  </Switch>
)