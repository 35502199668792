import { RouteComponentProps } from 'react-router-dom';
import { IState, ReduxMap } from '../../redux/redux';
import { useEffect, useState } from 'react';
import { ServerResponse } from '../../utils/Server';
import { ISchoolAffiliateFlowInfo, ISchoolAffiliateInfo } from './schoolTypes';
import StoreX, { store } from '../../redux/oldStore';
import { toast } from 'react-toastify';
import Button from '../_Core/Button';
import SchoolAffiliation from './SchoolAffiliation';
import Icon, { IconType } from '../Icon/Icon';

interface IProps extends IState, RouteComponentProps<IMatchParams> {}

interface IMatchParams {
  affiliateId?: string;
}

const SchoolAffilateForm = (props: IProps) => {
  const [show, setShow] = useState<'landing' | 'form' | 'done'>('landing');
  const [affiliateId, setAffiliateId] = useState<string>();
  const [divisionDic, setDivisionDic] = useState<Record<number, string>>({});
  const [seasonDic, setSeasonDic] = useState<Record<number, string>>({});
  const [affiliate, setAffiliate] = useState<ISchoolAffiliateInfo>();
  const [form, setForm] = useState<ISchoolAffiliateInfo>({});

  const [data, setData] = useState<ISchoolAffiliateFlowInfo>();

  useEffect(() => {
    init();
    if (props.match.params.affiliateId) {
      setAffiliateId(props.match.params.affiliateId);
    }
  }, []);

  useEffect(() => {}, [affiliateId]);

  const init = () => {
    store.server.getApi<ServerResponse<ISchoolAffiliateFlowInfo>>(`../SchoolAffiliate/AffiliateInfo/${affiliateId ?? props.match.params.affiliateId}`).then((x) => {
      if (x.Success) {
        setData(x.Value);
        setAffiliate(x.Value.affiliates[0] ?? {});
        setForm(x.Value.affiliates[0] ?? {});
        let dic = {};
        x.Value.divisions?.forEach((r) => {
          dic[+r.id] = r.name;
        });
        setDivisionDic(dic);

        let seaDic = {};
        x.Value.seasons?.forEach((r) => {
          seaDic[r.id] = r.name;
        });
        setSeasonDic(seaDic);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const showReviewData = ()=>{
    toast.dismiss();

    let request = {code:form.SchoolCode, password:form.SchoolUpdatePassword};

    store.server.postApi<ServerResponse<ISchoolAffiliateInfo>>(`../SchoolAffiliate/VerifyAccess`, request)
    .then((x) => {
        if (x.Success) {
          if(x.Value){
            setAffiliate(x.Value);
            setShow('form');
          } else {
            toast.warning('The key and/or password you provided are not valid.');
          }
        } else {
          toast.error(x.Message);
        }
      });
    //display message
    //show form
  };

  const saveAndParticipate = (r:ISchoolAffiliateInfo)=>{
    //SaveAndParticipate
    toast.dismiss();

    let isStaff = props.User?.Admin ?? false;
    let errors: string[] = [];

    if((r.EstimatedNumberOfEntries??0) <= 0) errors.push('You must enter a valid number of projects and it must be more than 0.');
    if((r.EstimatedNumberOfTeams??0) <= 0) errors.push('You must enter a valid number of teams and it must be more than 0.');

    if(!r.NoIrb){
      if(!r.IrbAdminName) errors.push('You must enter the IRB Administrator\'s Name.');
      if(!r.IrbMedName) errors.push('You must enter the IRB Mecial Professional\'s Name.');
      if(!r.IrbEducatorName) errors.push('You must enter the IRB Teacher 1\'s Name.');
      if(!r.IrbEducator2Name) errors.push('You must enter the IRB Teacher 2\'s Name.');
    }

    if (errors.length > 0) {
      toast.warning(
        <ul>
          {errors.map((x, i) => {
            return <li key={i}>{x}</li>;
          })}
        </ul>
      );
      if(!isStaff || !window.confirm('We have detected missing data do you want to save this record anyway?')){
      return;
      }
    }
    
    store.server.postApi<ServerResponse<ISchoolAffiliateInfo>>(`../SchoolAffiliate/SaveAndParticipate`, r)
    .then((x) => {
        if (x.Success) {
            setAffiliate(x.Value);
            setForm(x.Value);
            setShow('done');
        } else {
          toast.error(x.Message);
        }
      });

  };

  return (
    <>
    {data && <>
    <div className='flex-between'>
        <div>
        <h2>{data.name}</h2>
          {data.affiliates[0] && data.affiliates[0].Division && <h4>{divisionDic[data.affiliates[0].Division]}</h4>}

</div>
{props.User && props.User.Admin && <div>
  <span className='text-info fa-2x'><Icon type={IconType.unlock}/> Staff</span>
    
  </div>}
<div>
    {affiliate && affiliate.InvoiceId && <>
        <a className='btn btn-secondary' href={StoreX.BuildUrl(`../purchase/getinvoice/${affiliate.InvoiceId}`)} target='_blank'>
        <Icon type={IconType.invoice}/>
        View & Pay Invoice
        </a>
    </>}
</div>
</div>
          <hr/>
    </>}
      {data && show === 'landing' && (
        <>
          <div dangerouslySetInnerHTML={{ __html: data.LandingText ?? '' }} />
          <br />
          <div className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="SchoolCode">
                Affiliate Key
              </label>
              <div className="col-sm-7">
                <input
                  id={'SchoolCode'}
                  className="form-control"
                  type="text"
                  value={form.SchoolCode ?? ''}
                  onChange={(x) => {
                    setForm({ ...form, SchoolCode: x.target.value });
                  }}
                  maxLength={50}
                />
              </div>
            </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="SchoolUpdatePassword">
              Password
            </label>
            <div className="col-sm-7">
              <input
                id={'SchoolUpdatePassword'}
                className="form-control"
                type="text"
                value={form.SchoolUpdatePassword ?? ''}
                onChange={(x) => {
                  setForm({ ...form, SchoolUpdatePassword: x.target.value });
                }}
                maxLength={20}
              />
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-offset-4 col-sm-7'>
                <Button type="new" text="Review Affiliate Information" onClick={showReviewData} />
            </div>
          </div>

          </div>
          <hr />
        </>
      )}

      {data && affiliate && show === 'form' && <>

        <div dangerouslySetInnerHTML={{ __html: data.FormText ?? '' }} />
        <br/>
        <SchoolAffiliation 
        state={props} 
        data={data} 
        record={affiliate} 
        footerText={data.ConsentText}
        saveBtnText='Affiliate'
        onRemoved={(x)=> { }} 
        onSaved={(x)=> { saveAndParticipate(x);}} 
        overrideSave={true}
         />
      </>}

      {data && affiliate && show === 'done' && <>
        
        <div dangerouslySetInnerHTML={{ __html: data.DoneText ?? '' }} />
        <br/>
        
        <a className='btn btn-secondary' href={StoreX.BuildUrl(`../purchase/getinvoice/${affiliate.InvoiceId}`)} target='_blank'>
        <Icon type={IconType.invoice}/>
        View & Pay Invoice
        </a>
      </>}
    </>
  );
};

export default ReduxMap(SchoolAffilateForm);
