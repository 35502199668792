import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import StoreX from "../../redux/oldStore";
import { ServerResponse } from "../../utils/Server";
import { v4 as uid } from "uuid";

import Icon, { IconType } from "../Icon/Icon";
import Editor from "./Editor";

import { IFieldInfo, IFormDetail, IFormGroup, IFormSupportingDocument, IFormWizardRule } from "./FormTypes";
import FileUpload from "./FileUpload";
import FieldsManager from "./FieldsManager";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Selectable } from "../../utils/CommonTypes/CommonTypes";
import FormsWizardConditionsManager from "./FormsWizardConditionsManager";
import FormsSupportingDocumentManager from "./FormsSupportingDocumentManager";
import PdfViewer from "../PdfViewer/PdfViewer";
import { IListItem } from "../_Core/CoreTypes";
import Select from "react-select";
import {FormatDate} from '../../utils/Tools.ts'

interface IFormBuilderProps {
  form: IFormDetail;
  close:Function;
  update:Function;
  packet:IFormGroup;
  signers: Selectable[];
  
  divisions: IListItem[];
  categories: IListItem[];
  entryTypes: IListItem[];
}

const FormBuilder = (props: IFormBuilderProps) => {
  const [form, setForm] = useState<IFormDetail>(props.form);
  const [signers, setSigners] = useState<Selectable[]>(props.signers ?? []);


  
  const save = (close?: boolean) => {
    let p = form;
    saveIt(p, close);
  };

  const saveIt = (formToSave, close?:boolean) =>{ 
    StoreX.instance.server
    .postApi<ServerResponse<IFormDetail>>(`../GenericForms/SaveForm`,formToSave)
    .then((x) => {
      if (x.Success) {
        setForm(x.Value);
        props.update(form, signers);
        if(close === true) setTimeout(props.close, 500);
      } else {
        toast.error(`Error saving Form. ${x.Message}`, {
          autoClose: false,
        });
      }
    });
  };

  const uploadFile = (files) =>{
    let request = form;
    if(!request.Name) request.Name = files[0].name;

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/UploadFileForForm`, request, files)
    .then(x=>{
      if (x.Success) {
          setForm(x.Value);
          props.update(form, signers);
        } else {
          toast.error(`Error uploading file for form. ${x.Message}`, {
            autoClose: false,
          });
        }
    });
};

const removeFile = (files) =>{
    let request = form;

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/RemoveFileFromForm`, request, files)
    .then(x=>{
      if (x.Success) {
          setForm(x.Value);
          props.update(form, signers);
        } else {
          toast.error(`Error removing file for form. ${x.Message}`, {
            autoClose: false,
          });
        }
    });
};
const uploadFileNew = (files) =>{
    let request = form;
    if(!request.Name) request.Name = files[0].name;

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/UploadNewFileForForm`, request, files)
    .then(x=>{
      if (x.Success) {
          setForm(x.Value);
          props.update(form, signers);
        } else {
          toast.error(`Error uploading file for form. ${x.Message}`, {
            autoClose: false,
          });
        }
    });
};

const removeFileNew = (files) =>{
    let request = form;

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/RemoveNewFileFromForm`, request, files)
    .then(x=>{
      if (x.Success) {
          setForm(x.Value);
          props.update(form, signers);
        } else {
          toast.error(`Error removing file for form. ${x.Message}`, {
            autoClose: false,
          });
        }
    });
};

  const saveAndClose = () => {
    save(true);
  };

  const updateFields = (fields:IFieldInfo[], signers2:Selectable[])=>{
      saveIt({...form, Fields:fields},false);
      setSigners([...signers2??[]]);
  };

  const updateAndSaveRules = (rules:IFormWizardRule[])=>{
    let f = {...form, Rules:rules};
    saveIt(f, false);
  };

  const updateAndSaveSupportingDocuments = (docs:IFormSupportingDocument[])=>{
    let f = {...form, SupportingDocuments:docs};
    saveIt(f, false);
  };



  return (
    <>
      <Tabs>
          <TabList>
              <Tab>Form</Tab>
              <Tab>Fields</Tab>
              <Tab>Use Conditions</Tab>
              <Tab>Supporting Documents</Tab>
          </TabList>
          <TabPanel>
              
      <h4>Form Builder</h4>
      Fill out the following form to start creating the set of custom forms you
      need to use and collect.
      <hr />
              <div className="form-horizontal">
              <div className="form-group">
          <label htmlFor="FormName" className="control-label col-sm-4">
            Form Name
          </label>
          <div className="col-sm-8">
            <input
              className="form-control"
              id="FormName"
              type="text"
              value={form.Name}
              onChange={(e) => {
                setForm({ ...form, Name: e.target.value });
              }}
              maxLength={250}
            />
          </div>
        </div>              
        <div className="form-group">
          <label htmlFor="FormCode" className="control-label col-sm-4">
            Form Code (Abbreviation)
          </label>
          <div className="col-sm-3">
            <input
              className="form-control"
              id="FormCode"
              type="text"
              value={form.Abbreviation}
              onChange={(e) => {
                setForm({ ...form, Abbreviation: e.target.value });
              }}
              maxLength={4}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input type='checkbox'
            id="form-file-per-owner"
            checked={form.FilePerOwner}
            onChange={(e)=>{setForm({...form, FilePerOwner:e.target.checked})}}
            />

            <label className="control-label" htmlFor="form-file-per-owner">
              File per owner (Team Member)
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input type='checkbox'
            id="userInteractiveForm"
            checked={form.UseInteractiveForm}
            onChange={(e)=>{setForm({...form, UseInteractiveForm:e.target.checked})}}
            />

            <label className="control-label" htmlFor="userInteractiveForm">
              Is this form interactive? Meaning the user can fill it out online.
            </label>
          </div>
        </div>
{form.UseInteractiveForm && <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input type='checkbox'
            id="UseUploaded"
            checked={form.UseUploaded}
            onChange={(e)=>{setForm({...form, UseUploaded:e.target.checked})}}
            />

            <label className="control-label" htmlFor="UseUploaded">
              Use uploaded file as the interactive form.
            </label>
          </div>
        </div>}
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input type='checkbox'
            id="FormRequired"
            checked={form.Required}
            onChange={(e)=>{setForm({...form, Required:e.target.checked})}}
            />

            <label className="control-label" htmlFor="FormRequired">
              By default this form is required.
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input type='checkbox'
            id="form-file-wizard"
            checked={form.UsedBasedOnWizard}
            onChange={(e)=>{setForm({...form, UsedBasedOnWizard:e.target.checked})}}
            />

            <label className="control-label" htmlFor="form-file-wizard">
              If needed is based on wizard.
            </label>
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-sm-4">Description</label>
          <div className="col-sm-8">
            <Editor
              options={"simple"}
              value={form.Description}
              changed={(v) => {
                setForm({ ...form, Description: v });
              }}
              height="short"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="filePath">File template</label>
          <div className="col-sm-8">
            <FileUpload 
            id="filePath"
            fileKey={form.FilePath ? form.PublicId : null}
            fileType={"file-template"} 
            exts={[".pdf"]} 
            uploadFile={uploadFile} 
            removeFile={removeFile}
            formNotImage={true}
            formShowPreview={false}
            />
          </div>
        </div>
{form?.FilePath && <div className="form-group">
          <label className="control-label col-sm-4" htmlFor="NewFileTemplate">Map New Template File</label>
          <div className="col-sm-8">
            <FileUpload 
            id="NewFileTemplate"
            fileKey={(form.NewFilePath ? form.PublicId+'?new=1': null)}
            fileType={"file-template"} 
            exts={[".pdf"]} 
            uploadFile={uploadFileNew} 
            removeFile={removeFileNew}
            formNotImage={true}
            formShowPreview={false}
            />
          </div>
        </div>}

        <h3>Form Access & Visibility</h3>
        <div className="form-group">
          <label htmlFor="FormUploadDeadline" className="control-label col-sm-4">
            Upload Deadline
          </label>
          <div className="col-sm-8">
            <input
              className="form-control"
              id="FormUploadDeadline"
              type="datetime-local"
              
              value={FormatDate(form.UploadDeadline,'input-date-time', true)}
              onChange={(e) => {
                setForm({ ...form, UploadDeadline: e.target.value });
              }}
              maxLength={50}
              placeholder="mm/dd/yyyy hh:mm"
            />
          </div>
        </div>
        
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input type='checkbox'
            id="VisibleInJudging"
            checked={form.VisibleInJudging}
            onChange={(e)=>{setForm({...form, VisibleInJudging:e.target.checked})}}
            />

            <label className="control-label" htmlFor="VisibleInJudging">
              This form is visible in online judging.
            </label>
          </div>
        </div>
        
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input type='checkbox'
            id="VisibleInShowcase"
            checked={form.VisibleInShowcase}
            onChange={(e)=>{setForm({...form, VisibleInShowcase:e.target.checked})}}
            />

            <label className="control-label" htmlFor="VisibleInShowcase">
              This form is visible in the showcase.
            </label>
          </div>
        </div>

        
        <div className="form-group">
          <div className="col-sm-8 col-sm-offset-4">
            <input type='checkbox'
            id="IncludeInPacketDownload"
            checked={form.IncludeInPacketDownload}
            onChange={(e)=>{setForm({...form, IncludeInPacketDownload:e.target.checked})}}
            />

            <label className="control-label" htmlFor="IncludeInPacketDownload">
              Include this form in the packet download.
            </label>
          </div>
        </div>

        <h3>Form Filters</h3>
                  If this only applies to a specific types you can set those filters here. If you leave these blank the form
                  will be available to all.

                  <div className="form-group">
                    <label
                      htmlFor="EntryTypes"
                      className="control-label col-sm-4"
                    >
                      Entry Types
                    </label>
                    <div className="col-sm-8">
                      <Select
                      id="EntryTypes"
                        options={props.entryTypes.map((x) => {return {label:x.name, value:x.id}})}
                        value={props.entryTypes.filter((x) => form.ForEntryTypes?.includes(+x.id)).map((x) => {return {label:x.name, value:x.id}})}
                        onChange={(e) => {
                          setForm({ ...form, ForEntryTypes: e?.map((x) => +x.value) ?? [] });
                        }}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="Categories"
                      className="control-label col-sm-4"
                    >
                      Categories
                    </label>
                    <div className="col-sm-8">
                      <Select
                      id="Categories"
                        options={props.categories.map((x) => {return {label:x.name, value:x.id}})}
                        value={props.categories.filter((x) => form.ForCategories?.includes(+x.id)).map((x) => {return {label:x.name, value:x.id}})}
                        onChange={(e) => {
                          setForm({ ...form, ForCategories: e?.map((x) => +x.value) ?? [] });
                        }}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="Divisions"
                      className="control-label col-sm-4"
                    >
                      Divisions
                    </label>
                    <div className="col-sm-8">
                      <Select
                      id="Divisions"
                        options={props.divisions.map((x) => {return {label:x.name, value:x.id}})}
                        value={props.divisions.filter((x) => form.ForDivisions?.includes(+x.id)).map((x) => {return {label:x.name, value:x.id}})}
                        onChange={(e) => {
                          setForm({ ...form, ForDivisions: e?.map((x) => +x.value) ?? [] });
                        }}
                        isMulti={true}
                      />
                    </div>
                  </div>

              </div>
          </TabPanel>
          <TabPanel>

          <h4>Form Builder Fields</h4>
      Below is a list of fieds for this form, once you have pulled fields from the form it is recommened that you do not do that again as it will remove your current fields. If you don't have any fields listed, you can try and pull fields from your upload file. If that is not an option have you uploaded a file?
      <hr />
          <div className="form-group">
          <label className="control-label col-sm-4">Fields</label>
          <div className="col-sm-8">
            <FieldsManager 
            fields={form.Fields}
            fieldsUpdated={updateFields} 
            filePath={form.FilePath}
            formId={form.PublicId}
            signers={signers}
            form={form}
            />
          </div>
        </div>
          </TabPanel>
          <TabPanel>
            <FormsWizardConditionsManager packet={props.packet} form={form} updateAndSave={updateAndSaveRules} />
          </TabPanel>
          <TabPanel>
            <FormsSupportingDocumentManager packet={props.packet} form={form} updateAndSave={updateAndSaveRules} />
          </TabPanel>
      </Tabs>
      <div className="form-horizontal">
<hr/>

        <div className="form-group">
          <div className="col-sm-12 flex-between">
              <div>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={saveAndClose}
            >
              <Icon type={IconType.save} /> Save & Close Form
            </button>
            <button type="button" className="btn btn-default" onClick={()=>{save(false);}}>
              <Icon type={IconType.save} /> Save
            </button>
            </div>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                props.close();
              }}
            >
              <Icon type={IconType.close} /> Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormBuilder;
