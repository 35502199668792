import React, { FunctionComponent, useEffect, useState } from "react";
import { store } from "../../redux/oldStore";
import FileTypeManagementComponent from "./FileTypeManagementComponent";
import FileUploadComponent from "./FileUploadComponent";
import FileViewComponent from "./FileViewComponent";
import Modal from "./Modal";
import {
  buildButtons,
  firstOrDefault,
  isFileRequiredWithOverride,
  projectHeader,
} from "./paperworkReviewUtilities";
import {
  button,
  closeModalHookDef,
  fileRemovedHookDef,
  fileType,
  fileTypeRequirementRequest,
  project,
  requirementUpdateHookDef,
  updateFileHookDef,
  uploadFileHookDef,
} from "./paperworkTypes";

type manageProjectPaperworkComponentProps = {
  fileTypes: fileType[];
  project: project;
  fileUpdateHook: updateFileHookDef;
  fileUploadHook: uploadFileHookDef;
  fileRemovedHook: fileRemovedHookDef;
  requirementUpdateHook: requirementUpdateHookDef;
  parentCloseModalHook: closeModalHookDef;
};

type modalData = {
  component: JSX.Element;
  header: JSX.Element;
};

type buttonWithOverride = button & {
  overrideValue?: boolean;
  defaultValue: boolean;
};

const ManageProjectPaperworkComponent: FunctionComponent<manageProjectPaperworkComponentProps> = ({
  fileTypes,
  project,
  fileRemovedHook,
  fileUpdateHook,
  fileUploadHook,
  requirementUpdateHook,
  parentCloseModalHook,
}: manageProjectPaperworkComponentProps) => {
  const [showModal, setShowModal] = useState(false);
  const [fileButtons, setFileButtons] = useState(new Array<buttonWithOverride>());
  const [modalData, setModalData] = useState<modalData>();

  useEffect(() => {
    //create the buttons:
    const buttons = buildButtons(
      project.UploadedFiles,
      fileTypes,
      project.FileTypeRequirementOverrides,
      true, undefined, undefined, undefined, undefined
    );

    const overrideButtons = buttons.map((b) => {
      const overrideObject = firstOrDefault(
        project.FileTypeRequirementOverrides,
        (o) => {
          return o.fileTypeId === b.fileType;
        }
      );
      let overrideValue: boolean | undefined = undefined;
      if (overrideObject === null || overrideObject === undefined) {
        overrideValue = undefined;
      } else {
        overrideValue = overrideObject.isRequired;
      }
      const ob: buttonWithOverride = {
        ...b,
        defaultValue: isFileRequiredWithOverride(
          b.fileId || 0,
          project.FileTypeRequirementOverrides,
          false
        ),
        overrideValue: overrideValue,
      };
      return ob;
    });
    setFileButtons(overrideButtons);
  }, []);

  //methods:
  const getFaImageClass = (button: button) => {
    switch (button.status) {
      case "Required":
        return "fal fa-file-upload fa-2x text-muted";
      case "NeedsWork":
        return "fal fa-file-exclamation fa-2x text-danger";
      case "Pending":
        return "fal fa-file-minus fa-2x text-warning";
      case "Accepted":
        return "fal fa-file-check fa-2x text-success";
      default:
        return "fal fa-file fa-2x text-info";
    }
  };

  const getViewUploadButtonText = (button: button) => {
    return sanitizeFileId(button) > -1 ? (
      <>
        <i className="fa fa-eye"></i> view
      </>
    ) : (
      <>
        <i className="fa fa-cloud-upload"></i> upload
      </>
    );
  };

  const sanitizeFileId = (button: button) => {
    const fileId = button.fileId ?? -1;
    return fileId;
  };

  const closeModalHook: closeModalHookDef = () => {
    close();
  };

  const close = () => {
    setShowModal(false);
  };

  const onViewUpdateClick = (button: button) => {
    if (sanitizeFileId(button) > -1) {
      const selectedFile = getFile(button.fileId ?? 0);
      setModalData({
        component: (
          <FileViewComponent
            project={project}
            fileX={selectedFile}
            filetypes={fileTypes}
            closeModalHook={closeModalHook}
            fileUpdateHook={fileUpdateHook}
            fileUploadHook={fileUploadHook}
            fileRemovedHook={fileRemovedHook}
          />
        ),
        header: projectHeader(project),
      });
    } else {
      setModalData({
        component: (
          <FileUploadComponent
            closeModalHook={closeModalHook}
            fileUploadedHook={fileUploadHook}
            fileTypes={fileTypes}
            selectedFileTypeId={button.fileType}
            project={project}
            fileId={sanitizeFileId(button)}
          />
        ),
        header: projectHeader(project),
      });
    }
    setShowModal(true);
  };

  const onFileListItemClick = (button: button) => {
    setModalData({
      component: <FileTypeManagementComponent fileType={button.fileType} />,
      header: projectHeader(project),
    });
  };

  const getFile = (fileId: number) => {
    return project.UploadedFiles.filter(function (f) {
      return f.id === fileId;
    })[0];
  };

  // const toggleRequired= (button:button) => { todo:  I think this is not needed.
  //     button.required = !button.required;
  // }

  const saveRequiredFileOption = async (evt: React.MouseEvent) => {
    // send requiredStatus to server
    evt.stopPropagation();

    const success = (data: fileTypeRequirementRequest) => {
      console.log(data);
      requirementUpdateHook();
      parentCloseModalHook();
      //refresh the file requirements?  Do we need to refresh the whole paperwork page?
    };
    const fail = function (error: any) {
      console.log(error);
      //self.uploadError = response.Message;
      //show an error message?
    };

    const fileOverrides = fileButtons
      .filter((b) => b.overrideValue !== null && b.overrideValue !== undefined)
      .map((b) => {
        if (b.overrideValue === null || b.overrideValue === undefined) {
          throw new Error("this thing is undefined.");
        }
        return {
          fileTypeId: b.fileType,
          isRequired: b.overrideValue,
        };
      });
    const overrideRequest: fileTypeRequirementRequest = {
      projectKey: project.ProjectKey,
      fileTypeRequirementOverrides: fileOverrides,
    };

    try {
      const result = await store.server.postApiWithServerResponse<fileTypeRequirementRequest>(
        `../PaperworkReview/FileTypeRequirementOverrides`,
        { overrideRequest }
      );
      if (result?.Value) {
        success(result?.Value);
      } else {
        fail(result);
      }
    } catch (error) {
      fail(error);
    }
  };

  const setOverride = (
    button: buttonWithOverride,
    value: boolean | undefined
  ) => {
    const stateButtonsCopy = [...fileButtons];
    const matchingButton = stateButtonsCopy.find(
      (x) => x.fileId === button.fileId && x.fileType === button.fileType
    );
    if (!matchingButton) {
      return;
    }
    matchingButton.overrideValue = value;
    setFileButtons(stateButtonsCopy);
  };

  const getOverrideDisplayValue = (button: buttonWithOverride) => {
    if (button.overrideValue === true) {
      return "Force Required";
    } else if (button.overrideValue === false) {
      return "Force Not Required";
    } else {
      return "No Override";
    }
  };

  return (
    <>
      {showModal && modalData?.component && (
        <Modal
          closeModalHook={closeModalHook}
          body={modalData?.component}
          header={modalData.header}
          footer={<>&nbsp;</>}
        />
      )}
      <div>
        {fileButtons.map((button, index) => (
          <React.Fragment key={index}>
            <div
              className="project-tile flex-space-between"
              key={button.fileType}
            >
              <div>
                <i className={getFaImageClass(button)}></i>

                <strong>
                  {button.required && (
                    <span>
                      <strong>*</strong>
                    </span>
                  )}
                  {button.fileName}
                </strong>
              </div>
              <div className="paperwork-list-btn-bar">
                   
                <button
                  type="button"
                  className="btn btn-xs btn-primary"
                  onClick={() => onViewUpdateClick(button)}
                >
                  {getViewUploadButtonText(button)}
                </button>
<div>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-xs dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {getOverrideDisplayValue(button)}{" "}
                    <span className="caret"></span>
                  </button>
                  <ul key={index} className="dropdown-menu">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    <li>
                      <a
                        href="#"
                        onClick={() => setOverride(button, undefined)}
                      >
                        No Override
                      </a>
                    </li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    <li>
                      <a href="#" onClick={() => setOverride(button, true)}>
                        Force Required
                      </a>
                    </li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    <li>
                      <a href="#" onClick={() => setOverride(button, false)}>
                        Force Not Required
                      </a>
                    </li>
                  </ul>
                </div>
                </div>        
              </div>
            </div>
          </React.Fragment>
        ))}
        <span>* this file is required.</span>
        <br />
        <br />
        {/* translate click.self in the close method. */}
        <button type="button" className="btn" onClick={parentCloseModalHook}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={saveRequiredFileOption}
        >
          Save File Requirements
        </button>
      </div>
    </>
  );
};

export default ManageProjectPaperworkComponent;
