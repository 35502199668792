import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { toast } from 'react-toastify';
import StoreX from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { IFormDetail, IFormGroup, IFormPacketInfo, IFormSupportingDocument, ISigner, ISignerInfo, ISignerRequested, ISignRequest } from './FormTypes';
import PdfViewer from '../PdfViewer/PdfViewer';
import Icon, { IconType } from '../Icon/Icon';
import FileUpload from './FileUpload';
import { resetIdCounter, Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Modal from '../Modal/Modal';
import Editor from './Editor';
import { v4 as uid } from 'uuid';
import QuestionWizard from './QuestionWizard';
import { FormatDate } from '../../utils/Tools';
import Button from '../_Core/Button';
import { IEntryInfoApi } from '../_Core/CoreTypes';

interface IGenericFormsLandingProps extends RouteComponentProps<MatchParams> {
  packetId?: string;
  ownerId?: string;
  isSign?: boolean;
  isReview?: boolean;
  isReviewer?: boolean;
  mode?: 'review' | undefined;
  statusUpdated?: Function;
  selectedFileId?: string;
  close?:()=>void;
}

interface MatchParams {
  groupId: string;
  projectId: string;
  signerId: string;
}

interface IReviewForm {
  Comments?: string;
  PrivateComments?: string;
  Status?: string;
}

const GenericFormsLanding = (props: IGenericFormsLandingProps) => {
  const [data, setData] = useState<IFormPacketInfo>();
  const [packet, setFormGroup] = useState<IFormGroup>();
  const [reviewForm, setReviewForm] = useState<IReviewForm>({});
  const [selectedForm, setSelectedForm] = useState<IFormDetail>();
  const [packetId, setPacketId] = useState<string>(props.packetId ?? props.match.params.groupId);
  const [showSignRequestModal, setShowSignRequestModal] = useState<boolean>(false);
  const [selectedSignerInfo, setSelectedSignerInfo] = useState<ISignerInfo>();
  const [signer, setSigner] = useState<ISigner>();
  const [form, setForm] = useState<any>({ sendEmail: true, sendText: false });
  const [signRequestedInfo, setSignRequestedInfo] = useState<ISignerRequested>();
  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [isSelfSigning, setIsSelfSigning] = useState<boolean>(false);
  const [showSignerModal, setShowSignerModal] = useState<boolean>(false);
  const [signRequestHistory, setSignRequestHistory] = useState<ISignRequest>();
  const [showWizardModal, setShowWizardModal] = useState<boolean>(false);
  const [showHidden, setShowHidden] = useState<boolean>(false);
  const [showReviewAndCommentModal, setShowReviewAndCommentModal] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (packet && packet.WizardValues) {
      let forms = packet.Forms;
      forms.forEach((x) => {
        x.Hidden = false;

        if (x.UsedBasedOnWizard) {
          let rules = x.Rules?.sort((a, b) => a.Order - b.Order) ?? [];
          let require = false;

          rules.forEach((r) => {
            r.Questions?.forEach((q) => {
              let value = packet.WizardValues[q];
              if (value === true || value === 'true') {
                if (r.OnTrueRequire) {
                  require = true;
                } else {
                  require = false;
                }
              }
            });
          });
          x.Hidden = !require;
        }
      });

      setFormGroup({ ...packet, Forms: [...forms] });
    }
  }, [packet?.WizardValues]);

  useEffect(() => {
    if (props.isSign) {
      let s = packet?.Signers.find((x) => x.PublicId === props.match.params.signerId);
      if (s) {
        setIsSigning(true);
        setSelectedSignerInfo(s);
        if (!signer) setShowSignerModal(true);
      }
    }
  }, [props.isSign, packet?.Signers]);

  useEffect(() => {
    if (selectedForm) {
      //do stuff
    }
  }, [selectedForm]);

  const init = () => {
    StoreX.instance.server
      .postApi<ServerResponse<IFormPacketInfo>>(`../GenericForms/FormGroup`, {
        groupId: packetId,
        projectKey: props.ownerId ?? props.match.params.projectId,
        signerId: props.match.params.signerId,
        isReview: props.isReview,
      })
      .then((x) => {
        if (x.Success) {
          setFormGroup(x.Value.packet);
          setData(x.Value);

          if(selectedForm){
            let f = x.Value.packet.Forms?.find(x=>x.ResultsId === selectedForm.ResultsId);
            setSelectedForm(f);
          } else if (props.selectedFileId){
            let f = x.Value.packet.Forms?.find(x=>x.ResultsId === props.selectedFileId);
            setSelectedForm(f);
          }



          let p = x.Value.packet;

          if (p.WizardQuestions && p.WizardQuestions.length > 0) {
            let keys = Object.keys(p.WizardValues ?? {});
            let missing = p.WizardQuestions.filter((r) => !keys.find((k) => k === r.PublicId));
            if (missing.length > 0) {
              setShowWizardModal(true);
            }
          }
        } else {
          toast.error(x.Message, { autoClose: false });
        }
      });
  };

  const mapFormTiles = () => {
    if (!packet?.Forms.length) {
      return <div>No forms found, for this packet.</div>;
    }

    return packet.Forms.map((x, i) => {
      let sigFields = x.Fields?.filter((f) => f.SignatureOwner && f.Type === 'Tx' && (f.SignatureType === 'Signature' || f.SignatureType === 'DateSigned' || f.SignatureType === 'Initials')) ?? [];
      let allSigned = sigFields.filter((f) => !x.Values[f.FormKey ?? '#']).length === 0;
      let fields = sigFields?.filter((f) => f.SignatureOwner === selectedSignerInfo?.PublicId) ?? [];
      let needsSigned = isSigning && signer && fields.length > 0;
      let signersFieldsAllSigned = needsSigned && !fields.find((f) => !x.Values[f.FormKey ?? '#'] && !x.Hidden);
      return (
        <div
          key={`form-tile-${i}`}
          className={`form-tile ${needsSigned ? 'sign-me' : ''} ${x.Hidden ? 'form-hide' : ''}`}
          onClick={() => {
            setSelectedForm(x);
          }}
          title="click to view / edit form">
          <div className="banner">
            {x.Required && (
              <div className="warning text-danger">
                <Icon type={IconType.warning} /> Required
              </div>
            )}
            {x.UploadDeadline && (
              <div className="info text-info">
                <Icon type={IconType.lock} /> Locks {FormatDate(x.UploadDeadline, 'date-time', false)}
              </div>
            )}
          </div>
          <div className="title">{x.Name}</div>
          <hr />
          {x.FileKey && (
            <span title="file has been uploaded">
              <Icon type={IconType.filePdf} addClass="" /> File has been uploaded.
            </span>
          )}
          {needsSigned && (
            <div>
              {!signersFieldsAllSigned && (
                <div className="text-danger">
                  <Icon type={IconType.warning} /> Needs to be signed. Click to start.
                </div>
              )}
              {signersFieldsAllSigned && (
                <div className="text-success">
                  <Icon type={IconType.checkSquareO} /> All Signed!
                </div>
              )}
            </div>
          )}
          {!isSigning && !needsSigned && sigFields.length > 0 && !allSigned && (
            <div>
              <div className="text-info">
                <Icon type={IconType.warning} /> Needs to be signed.
              </div>
            </div>
          )}
          {!isSigning && !needsSigned && sigFields.length > 0 && allSigned && (
            <div>
              <div className="text-success">
                <Icon type={IconType.checkSquareO} /> Form has been signed!
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const formValuesUpdated = (publicId: string, values: any, close: boolean, supportingDoc?: string, files?: any) => {
    //update values on server ....
    let request = {
      formId: selectedForm?.PublicId,
      resultsId: selectedForm?.ResultsId,
      packetId: packet?.PublicId,
      subId: selectedForm?.SubId,
      values: values,
      name: selectedForm?.Name,
      projectKey: props.match.params.projectId ?? props.ownerId,
      signer: signer,
      SupportingDocumentId: supportingDoc ?? null,
    };

    if (selectedForm) {
      StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/SaveFormData`, request, files).then((x) => {
        if (x.Success) {
          toast.success(`${selectedForm?.Name} data saved.`);

          let docs = [...(selectedForm.SupportingDocuments ?? [])];
          if (supportingDoc) {
            for (let i = 0; i < docs.length; i++) {
              if (docs[i].PublicId == supportingDoc) {
                docs[i].FileKeys = x.Value.FileKeys ?? [];
                break;
              }
            }
          }

          if (close) setSelectedForm(undefined);
          else {
            setSelectedForm({
              ...selectedForm,
              Values: x.Value.Values,
              ResultsId: x.Value.ResultsId,
              SupportingDocuments: docs,
            });
          }

          if (packet) {
            let g = packet;
            for (let i = 0; i < g.Forms.length; i++) {
              let f = g?.Forms[i];
              if (f?.PublicId === publicId && f?.SubId === selectedForm.SubId) {
                // f.Values = values;
                // f.ResultsId = x.Value.ResultsId;
                // f.SupportingDocuments=docs;
                if (g.Forms) g.Forms[i] = x.Value;
                setSelectedForm(x.Value);
                break;
              }
            }
            setFormGroup(g);
          }
        } else {
          toast.error('Error saving form data: ' + x.Message, {
            autoClose: false,
          });
        }
      });
    }
  };

  const formClosed = () => {
    setSelectedForm(undefined);
  };

  const uploadSupportingDoc = (files: any, doc: IFormSupportingDocument) => {
    if (selectedForm) formValuesUpdated(selectedForm?.PublicId, {}, false, doc.PublicId, files);
  };

  const removeSupportingDoc = (doc: IFormSupportingDocument) => {
    if (doc.FileKeys && doc.FileKeys.length > 0) removeFile(doc.FileKeys[0], doc.PublicId);
  };

  const uploadFile = (files: any) => {
    if (selectedForm) formValuesUpdated(selectedForm?.PublicId, {}, false, undefined, files);
  };
  const removeFile = (docId?: string, supportingDocId?: string) => {
    let request = {
      fileKey: selectedForm?.FileKey,
      formId: selectedForm?.PublicId,
      resultsId: selectedForm?.ResultsId,
      projectKey: props.ownerId,
      SupportingDocumentId: docId ?? null,
    };

    if (docId) {
      //so we remove this file
      request.fileKey = docId;
    }
    if (selectedForm) {
      StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/removeUploadedForm`, request).then((x) => {
        if (x.Success) {
          toast.success(`${selectedForm?.Name} uploaded file removed.`);

          let docs = [...(selectedForm.SupportingDocuments ?? [])];
          if (docId) {
            for (let i = 0; i < docs.length; i++) {
              if (docs[i].PublicId == supportingDocId) {
                docs[i].FileKeys = x.Value.FileKeys ?? [];
                break;
              }
            }
          }

          setSelectedForm({
            ...selectedForm,
            FileKey: '',
            FileUrl: '',
            SupportingDocuments: docs,
          });

          if (packet) {
            let g = packet;
            for (let i = 0; i < g?.Forms.length; i++) {
              let f = g?.Forms[i];
              if (f && f.PublicId === selectedForm?.PublicId) {
                f.FileKey = '';
                f.FileUrl = '';
                f.SupportingDocuments = docs;
                break;
              }
            }
            setFormGroup(g);
          }
        } else {
          toast.error('Error removing file: ' + x.Message, {
            autoClose: false,
          });
        }
      });
    }
  };

  const clearSelected = () => {
    setSelectedForm(undefined);
  };

  const signRequested = (signerId: string) => {
    if (packet) {
      let signerInfo = packet.Signers.find((x) => x.PublicId === signerId);
      let currentForm = { ...selectedForm };

      let request = packet.SignRequests?.find((x) => x.SignerId == signerId);
      setSignRequestHistory(request ? request : undefined);

      setSelectedSignerInfo(signerInfo);
      if (!signerInfo?.IsSelf || (signerInfo?.IsSelf && currentForm.SubId && currentForm.SubId !== packet.Self.PublicId)) {
        setShowSignRequestModal(true);
      } else if (packet.Self?.IsSelf) {
        //setSigner({...packet.Self, SignatureInfoId:signerInfo.PublicId});
        setForm({
          ...form,
          signerName: packet.Self.Name,
          signerInitial: packet.Self.Initials,
          signerEmail: packet.Self.Email,
          signerId: packet.Self.PublicId,
          message: defaultEmailMessageForSignRequest(),
          textMessage: defaultTextMessageForSignRequest(),
        });
        setIsSelfSigning(true);
        setShowSignerModal(true);
        return;
      }

      if (signRequestedInfo?.Signer !== signerId) setSignRequestedInfo(undefined);

      setForm({
        ...form,
        email: '',
        phone: '',
        sendText: false,
        sendEmai: true,
        message: defaultEmailMessageForSignRequest(),
        textMessage: defaultTextMessageForSignRequest(),
      });
      //self sign???
    }
  };

  const sendSignRequest = () => {
    let r = {
      PacketId: packetId,
      ProjectKey: props.ownerId,
      SignerId: selectedSignerInfo?.PublicId,
      Message: form.message,
      Email: form.email,
      Text: form.sendText && form.phone ? true : false,
      Phone: form.phone,
      TextMessage: form.textMessage,
    };

    if (form.sendText && (!r.Phone || r.Phone.length < 10)) {
      toast.warning(`Please provide a valid number.`);
      return;
    }
    if (form.sendEmail && (!r.Email || r.Email.length < 8 || r.Email.indexOf('@') < 0 || r.Email.indexOf('.') < 0)) {
      toast.warning(`Please provide a valid email.`);
      return;
    }
    if ((form.sendEmail && (r.Message?.indexOf('[LINK]') ?? -1) < 0) || (form.sendText && (r.TextMessage?.indexOf('[LINK]') ?? -1) < 0)) {
      toast.error(`Your message must have "[LINK]"`);
      return;
    }

    StoreX.instance.server.postApi<ServerResponse<ISignerRequested>>(`../GenericForms/NotifySigner`, r).then((x) => {
      if (x.Success) {
        setSignRequestedInfo(x.Value);
        let d: IFormGroup = { ...packet };
        let nr: ISignRequest[] = d.SignRequests?.filter((x) => x.SignerId !== r.SignerId) ?? [];
        let now = new Date();
        let to: string[] = [];

        if (r.Text) {
          to.push(`Text: ${r.Phone}`);
        }
        if (r.Email) {
          to.push(`Email: ${r.Email}`);
        }

        nr.push({
          HasSignedSome: false,
          ShortSignRequestUrl: x.Value.Link,
          SignerId: r.SignerId,
          SentAt: `${d}`,
          SentTo: to,
        });
        d.SignRequests = nr;
        setFormGroup(d);
        toast.success('Request processed!');
      } else {
        toast.error(x.Message, { autoClose: false });
      }
    });
  };

  const agreeAndSign = () => {
    let s: ISigner;
    if (!signer) {
      s = {
        Email: form.signerEmail,
        Initials: form.signerInitial,
        Name: form.signerName,
        Phone: form.signerPhone,
        SignatureInfoId: selectedSignerInfo?.PublicId ?? '',
        PublicId: isSelfSigning ? packet?.Self.PublicId : uid(),
      };

      let errors: string[] = [];
      if (!s.Name || s.Name.length < 3) {
        errors.push('Please provide your full name for your signature.');
      }
      if (!s.Initials) {
        errors.push('Please provide your initials.');
      }
      if (!s.Phone && !s.Email) {
        errors.push('You must provide either a cell phone number or email.');
      }
      if (s.Phone && s.Phone.replace(/[^0-9]/g, '').length !== 10) {
        errors.push('Please provide a valid cell phone number.');
      }
      if (s.Email && (s.Email.indexOf('@') === -1 || s.Email.indexOf('.') === -1 || s.Email.indexOf('@') > s.Email.lastIndexOf('.'))) {
        errors.push('Please provide a valid email.');
      }

      if (errors.length > 0) {
        toast.error(
          <div>
            Oops, we need a bit more informaiton.
            <ul>
              {errors.map((x, i) => {
                return <li key={`error-${i}`}>{x}</li>;
              })}
            </ul>
          </div>,
          { autoClose: 10000 }
        );
        return;
      }
      //validate signer
    } else s = { ...signer };

    if (!form.signerAgree) {
      toast.info(`${s.Name ?? 'Hey'}, you must agree before you can sign.`);
      return;
    }
    if (isSelfSigning) {
      setIsSigning(true);
      setSelectedForm(undefined);
    }
    setSigner(s);
    setShowSignerModal(false);
  };

  const exitSigningMode = () => {
    setIsSigning(false);
    setIsSelfSigning(false);
    setSigner(undefined);
    setSelectedForm(undefined);
  };

  const defaultEmailMessageForSignRequest = () => {
    return '<div>Hi, <br/><br/>I have been working hard on my project and need to get your signature. Please click this link [LINK] and you will be able to review and sign my forms.<br/><br/>Thank you,<br/></div>';
  };

  const defaultTextMessageForSignRequest = () => {
    return 'Hi, Please click the [LINK] so you can review and sign my forms. Thanks!';
  };

  const saveWizard = (values: any) => {
    if (packet) setFormGroup({ ...packet, WizardValues: { ...values } });
    //save to server...
    let request = {
      packetId: packet?.PublicId,
      values: values,
      name: selectedForm?.Name,
      projectKey: props.match.params.projectId ?? props.ownerId,
    };

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/SaveFormData`, request).then((x) => {
      if (x.Success) {
        toast.success(`Wizard data saved.`);
      } else {
        toast.error('Error saving wizard data: ' + x.Message, {
          autoClose: false,
        });
      }
    });
  };

  const reviewUpdateStatus = () => {
    let request = {
      packetId: packet?.PublicId,
      status: reviewForm.Status,
      comment: reviewForm.Comments,
      formId: selectedForm?.PublicId,
      resultsId: selectedForm?.ResultsId,
      subId: selectedForm?.SubId,
      privateComments: reviewForm.PrivateComments,
    };

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/UpdateFormStatus`, request).then((x) => {
      if (x.Success) {
       
        toast.info(`Status updated, on ${selectedForm?.Name}`);
        init();
        
        if (props.statusUpdated) props.statusUpdated(request.status);
      } else {
        toast.error('Error saving wizard data: ' + x.Message, {
          autoClose: false,
        });
      }
    });
  };

  const updateStatus = () => {
    let request = {
      packetId: packet?.PublicId,
      status: form.reviewStatus,
      comment: form.reviewComment,
      formId: selectedForm?.PublicId,
      resultsId: selectedForm?.ResultsId,
      subId: selectedForm?.SubId,
      projectKey: props.match.params.projectId ?? props.ownerId,
    };

    StoreX.instance.server.postApi<ServerResponse<IFormDetail>>(`../GenericForms/UpdateStatus`, request).then((x) => {
      if (x.Success) {
        //clean up
        toast.info('Status updated!');
        setFormGroup({ ...packet, Status: request.status, Comments: [x.Value, ...(packet?.Comments ?? [])] });
        setForm({ ...form, reviewComment: '' });
        setShowReviewAndCommentModal(false);
        if (props.statusUpdated) props.statusUpdated(request.status);
      } else {
        toast.error('Error saving wizard data: ' + x.Message, {
          autoClose: false,
        });
      }
    });
  };

  const getStatus = (status: string) => {
    switch (status?.toLocaleLowerCase() ?? '') {
      case 'unknown':
        return <></>;
        break;
      case 'needswork':
        return (
          <>
            <span className="text-danger">
              <Icon type={IconType.checklist} /> Needs Work
            </span>
          </>
        );
        break;
      case 'underreview':
        return (
          <>
            <span className="text-info">
              <Icon type={IconType.checklist} /> Under Review
            </span>
          </>
        );
        break;
      case 'accepted':
        return (
          <>
            <span className="text-success">
              <Icon type={IconType.checklist} /> Accepted
            </span>
          </>
        );
        break;
    }
  };

  if (props.mode === 'review') {
    return (
      <>
        {packet && (
          <div className="generic-form-viewer">
            <div className="side-bar">
              <div className="entry-info">
                {data && data.entryInfo.Project&& <>
                  <div className='entry-id'>{data.entryInfo.Project.project_id}</div>
                  <div className='title'>{data.entryInfo.Project.title}</div>
                  {data.entryInfo.Project.categoryName && <div className='category-info'>Category: {data.entryInfo.Project.categoryName}</div>}
                  {data.entryInfo.Project.DivisionName && <div className='division'>Division: {data.entryInfo.Project.DivisionName}</div>}
                </>}
                <div className='owners-list'>
                {data && data.entryInfo.Owners && data.entryInfo.Owners.length > 0 && 
                  data.entryInfo.Owners.map((x, i) => {
                    return <div className='owner' key={`owner-${i}`}>{x.FirstName} {x.LastName}
                      <div className='school'>{x.School}</div>
                    </div>
                  })}
                  </div>
              </div>
              <div className="form-status">
                <label className="control-label" htmlFor='review-comments'>Comments</label>
                <textarea id={'review-comments'} maxLength={1000} className='form-control' rows={5}
                  value={reviewForm.Comments}
                  onChange={(e) => {
                    setReviewForm({ ...form, Comments: e.target.value });
                  }}
                />

                <br/>
                <label className="control-label" htmlFor='review-comments-private'>Private Notes</label>
                <textarea id={'review-comments-private'} maxLength={1000} className='form-control private' rows={5}
                               value={reviewForm.PrivateComments}
                               onChange={(e) => {
                                 setReviewForm({ ...form, PrivateComments: e.target.value });
                               }}
                />             
                <br/>
                <label className="control-label" htmlFor='review-status'>Change Status</label>
                <select id={'review-status'} className='form-control' value={reviewForm.Status ??''} onChange={(e) => {
                  setReviewForm({ ...form, Status: e.target.value });
                }}>
                  <option value=''>Select Status</option>
                  <option value='Accepted'>Accepted</option>
                  <option value='NeedsWork'>Needs Work</option>
                  <option value='UnderReview'>Under Review</option>
                  </select>

                  <br/>
                  <br/>
                  <Button onClick={reviewUpdateStatus} type='save' text='Save' />
                  </div>
            </div>
            <div className="main-content">
              <div className="-header">
                <div className='flex-between'>
                  <div>
                    <h3>
                      {selectedForm?.Name || "Select a Form to Review"}
                    </h3>
                    
                  </div>
                  <div>
                    <Button onClick={props.close} type='close' iconOnly={true} addClass='huge'/>
                  </div>
                </div>
              </div>
              <div className="main">

                
              <Tabs>
                <TabList>
                  {selectedForm && <>
                  {selectedForm.UseInteractiveForm && selectedForm.FilePath && (
                    <Tab>
                      Interactive <Icon type={IconType.filePdf} />
                    </Tab>
                  )}
                  <Tab>
                    Uploaded File <Icon type={IconType.upload} />
                  </Tab>
                  {selectedForm.SupportingDocuments?.length > 0 && <Tab>Supporting Documents</Tab>}
                  </>}
                  <Tab>Info</Tab>
                </TabList>
                {selectedForm && selectedForm.UseInteractiveForm && selectedForm.FilePath && (
                  <TabPanel>
                    <>
                      <PdfViewer
                        source={`../File/Form/${selectedForm.PublicId}`}
                        id={selectedForm.PublicId}
                        close={formClosed}
                        updateValues={formValuesUpdated}
                        values={selectedForm.Values}
                        fieldMetaData={selectedForm.Fields}
                        signer={signer}
                        signRequested={signRequested}
                        downloadFormUrl={
                          StoreX.BuildUrlWithFair(`../GenericForms/DownloadForm/`) +
                          `&groupId=${packetId}&formId=${selectedForm.PublicId}&resultsId=${selectedForm.ResultsId}&projectKey=${props.ownerId}`
                        }
                      />
                    </>
                  </TabPanel>
                )}
                {selectedForm && 
                               <TabPanel>
                  <>
                    {selectedForm.FileKey ? (
                      <PdfViewer
                        source={`../File/${selectedForm.FileKey}`}
                        id={selectedForm.PublicId}
                        close={formClosed}
                        updateValues={formValuesUpdated}
                        viewOnly={true}
                        values={selectedForm.Values}
                        fieldMetaData={selectedForm.Fields}
                        signer={signer}
                        signRequested={signRequested}
                        removeFile={() => {
                          window.confirm('Are you sure you want to remove this file?') && removeFile();
                        }}
                        downloadFormUrl={StoreX.BuildUrlWithFair(`../File/${selectedForm.FileKey}`)}
                      />
                    ) : (
                      <FileUpload
                        fileKey={selectedForm.FileKey}
                        fileUrl={selectedForm.FileUrl}
                        exts={['.pdf', '.jpg', '.jpeg', '.png']}
                        fileType={''}
                        uploadFile={uploadFile}
                        removeFile={removeFile}
                        convertToPdf={true}
                        formShowPreview={true}
                      />
                    )}
                  </>
                </TabPanel>
  }
                {selectedForm && selectedForm.SupportingDocuments?.length > 0 && (
                  <TabPanel>
                    <h3>Supporting Documents</h3>
                    Below are listed the supporting documents that may be needed for your packet.
                    <hr />
                    <div className="supporting-document-list">
                      {selectedForm.SupportingDocuments.map((x, i) => {
                        return (
                          <div key={`sd-${i}`}>
                            <h4>
                              <Icon type={IconType.filePdf} /> {x.Name}
                            </h4>
                            {x.Instructions && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: x.Instructions,
                                }}></div>
                            )}
                            <hr />
                            <FileUpload
                              fileKey={x.FileKeys && x.FileKeys.length > 0 ? x.FileKeys[0] : undefined}
                              exts={['.pdf']}
                              fileType={''}
                              uploadFile={(files) => {
                                uploadSupportingDoc(files, x);
                              }}
                              removeFile={() => {
                                removeSupportingDoc(x);
                              }}
                              formShowPreview={true}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </TabPanel>
                )}

                <TabPanel>

                    File Key: {selectedForm?.FileKey}<br/>
    Id: {selectedForm?.Id}<br/>
    Sub Id: {selectedForm?.SubId}<br/>
    Results Id: {selectedForm?.ResultsId}<br/>
    Results Id: {props.selectedFileId}<br/>
    File Key: {selectedForm?.FileKey}<br/>
                </TabPanel>
              </Tabs>

              </div>
              <div className="-footer">
                <div className='form-tiles'>
                {packet.Forms?.filter(x=>!x.Hidden).map((x, i) => {
                  let icon = <i className="fal fa-file-upload text-muted"></i>;
                  let label = x.Abbreviation || '';
                  
                  switch (x.Status?.toLowerCase()) {
                    case 'new':
                      icon = <i className="fal fa-file text-info"></i>;
                      break;
                    case 'pending':
                      icon = <i className="fal fa-file-minus text-warning"></i>;
                      break;
                    case 'reviewed':
                    case 'success':
                    case 'accepted':
                      icon = <i className="fal fa-file-check text-success"></i>;
                      break;
                    case 'needswork':
                    case 'failed':
                      icon = <i className="fal fa-file-exclamation text-danger"></i>;
                      break;                    
                  }

                  let isSelected = selectedForm && selectedForm?.ResultsId === x.ResultsId;

                  return (
                    <div key={`form-tile-${i}`} className={`form-tile ${isSelected?'is-selected':''}`} 
                    title={x.Name}
                    onClick={() => {
                      setSelectedForm(x);
                    }}>
                      {icon}
                      {label}

                      </div>
                  );
                })}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else
    return (
      <>
        <div className={`col-sm-12 ${showHidden ? 'show-hidden-forms' : ''}`}>
          {isSigning && (
            <>
              <div className="alert alert-info">
                <div className="flex-between">
                  <h3>
                    <Icon type={IconType.warning} /> {selectedSignerInfo?.Name} {signer?.Name && <>({signer.Name})</>} Is Signing
                  </h3>
                  <div>
                    {isSelfSigning && isSigning && (
                      <button className="btn btn-secondary" title="exit signing mode" onClick={exitSigningMode}>
                        <Icon type={IconType.close} />
                        Exit Signing Mode
                      </button>
                    )}
                  </div>
                </div>
                {selectedSignerInfo?.Instructions && (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedSignerInfo.Instructions,
                      }}></div>
                  </div>
                )}
              </div>
            </>
          )}
          {packet && !selectedForm && (
            <>
              <div className="flex-between">
                <h4>{packet.Name}</h4>
                {!isSigning && packet?.Status && <div className="packet-status">{getStatus(packet?.Status)}</div>}
                <div>
                  <a href={StoreX.BuildUrlWithFair(`../GenericForms/DownloadFormGroup/`) + `&groupId=${packetId}&projectKey=${props.ownerId}`} title="download all forms" target="_blank">
                    <Icon type={IconType.download} /> download forms
                  </a>
                </div>
              </div>

              <>
                {packet.Description && <div dangerouslySetInnerHTML={{ __html: packet.Description }}></div>}
                <hr />
                <div className="flex-between">
                  <div className="gen-form-tile-container flex-grow">
                    {mapFormTiles()}

                    {packet?.WizardQuestions?.length > 0 && (
                      <>
                        <div className={`form-tile flex-centered`} title="click to view / edit form">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            title="show wizard"
                            onClick={() => {
                              setShowWizardModal(true);
                            }}>
                            <Icon type={IconType.magic} /> Show Form Wizard
                          </button>
                        </div>

                        <div className={`form-tile flex-centered`} title="click to view / edit form">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            title="show wizard"
                            onClick={() => {
                              setShowHidden(!showHidden);
                            }}>
                            {showHidden ? (
                              <>
                                <Icon type={IconType.eyeNo} /> Hide Hidden Files
                              </>
                            ) : (
                              <>
                                <Icon type={IconType.eye} /> Show Hidden Files
                              </>
                            )}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  {props.isReview && (
                    <div className="review-panel">
                      <h4>Review & Comments</h4>
                      <hr />
                      <div className="comments-container">
                        {packet?.Comments?.length > 0 &&
                          packet.Comments.map((x, i) => {
                            return (
                              <div className="comment" key={`rv-c-${i}`}>
                                <div className="comment-by">
                                  <span>{x.ByName}</span>
                                  <span>{getStatus(x.Status)}</span>
                                  <span>{x.At}</span>
                                </div>
                                <div className="comment-message">{x.Comment}</div>
                              </div>
                            );
                          })}
                      </div>
                      {props.isReviewer && (
                        <div className="headroom-xl">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                              setShowReviewAndCommentModal(true);
                            }}>
                            <Icon type={IconType.textMsg} /> Update Status / Or Comment
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            </>
          )}

          {selectedForm && (
            <>
              <div className="flex-between">
                <h3>{selectedForm.Name}</h3>
                <div>
                  <button type="button" className="btn btn-default" onClick={clearSelected}>
                    <Icon type={IconType.close} /> Close / Back
                  </button>
                </div>
              </div>
              <Tabs>
                <TabList>
                  {selectedForm.UseInteractiveForm && selectedForm.FilePath && (
                    <Tab>
                      Online Interactive <Icon type={IconType.filePdf} />
                    </Tab>
                  )}
                  <Tab>
                    Upload File <Icon type={IconType.upload} />
                  </Tab>
                  {selectedForm.SupportingDocuments?.length > 0 && <Tab>Supporting Documents</Tab>}
                </TabList>
                {selectedForm.UseInteractiveForm && selectedForm.FilePath && (
                  <TabPanel>
                    <>
                      <PdfViewer
                        source={`../File/Form/${selectedForm.PublicId}`}
                        id={selectedForm.PublicId}
                        close={formClosed}
                        updateValues={formValuesUpdated}
                        values={selectedForm.Values}
                        fieldMetaData={selectedForm.Fields}
                        signer={signer}
                        signRequested={signRequested}
                        downloadFormUrl={
                          StoreX.BuildUrlWithFair(`../GenericForms/DownloadForm/`) +
                          `&groupId=${packetId}&formId=${selectedForm.PublicId}&resultsId=${selectedForm.ResultsId}&projectKey=${props.ownerId}`
                        }
                      />
                    </>
                  </TabPanel>
                )}
                <TabPanel>
                  <>
                    <h3 className="flex-between">
                      {selectedForm.Name}{' '}
                      {selectedForm.Required && (
                        <>
                          {' '}
                          <span className="text-danger">
                            <Icon type={IconType.warning} /> Required
                          </span>
                        </>
                      )}
                    </h3>

                    {selectedForm.FileKey ? (
                      <PdfViewer
                        source={`../File/${selectedForm.FileKey}`}
                        id={selectedForm.PublicId}
                        close={formClosed}
                        updateValues={formValuesUpdated}
                        viewOnly={true}
                        values={selectedForm.Values}
                        fieldMetaData={selectedForm.Fields}
                        signer={signer}
                        signRequested={signRequested}
                        removeFile={() => {
                          window.confirm('Are you sure you want to remove this file?') && removeFile();
                        }}
                        downloadFormUrl={StoreX.BuildUrlWithFair(`../File/${selectedForm.FileKey}`)}
                      />
                    ) : (
                      <FileUpload
                        fileKey={selectedForm.FileKey}
                        fileUrl={selectedForm.FileUrl}
                        exts={['.pdf', '.jpg', '.jpeg', '.png']}
                        fileType={''}
                        uploadFile={uploadFile}
                        removeFile={removeFile}
                        convertToPdf={true}
                        formShowPreview={true}
                      />
                    )}
                  </>
                </TabPanel>
                {selectedForm.SupportingDocuments?.length > 0 && (
                  <TabPanel>
                    <h3>Supporting Documents</h3>
                    Below are listed the supporting documents that may be needed for your packet.
                    <hr />
                    <div className="supporting-document-list">
                      {selectedForm.SupportingDocuments.map((x, i) => {
                        return (
                          <div key={`sd-${i}`}>
                            <h4>
                              <Icon type={IconType.filePdf} /> {x.Name}
                            </h4>
                            {x.Instructions && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: x.Instructions,
                                }}></div>
                            )}
                            <hr />
                            <FileUpload
                              fileKey={x.FileKeys && x.FileKeys.length > 0 ? x.FileKeys[0] : undefined}
                              exts={['.pdf']}
                              fileType={''}
                              uploadFile={(files) => {
                                uploadSupportingDoc(files, x);
                              }}
                              removeFile={() => {
                                removeSupportingDoc(x);
                              }}
                              formShowPreview={true}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </TabPanel>
                )}
              </Tabs>
            </>
          )}
        </div>

        {showSignRequestModal && (
          <Modal setModalOpen={setShowSignRequestModal} title={`Sign Request`} size={'l'}>
            <div>
              {selectedSignerInfo?.Description && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedSignerInfo?.Description,
                  }}
                />
              )}
            </div>
            <div>
              {!signRequestedInfo && (
                <>
                  {signRequestHistory && (
                    <div className="alert alert-info">
                      <h3>
                        <Icon type={IconType.warning} /> Signature Request Sent!
                      </h3>
                      <hr />
                      You've already sent this request on {signRequestHistory.SentAt}. You can copy the sign now link below or even request that we send it out again.
                      <br />
                      {signRequestHistory.SentTo && (
                        <div>
                          Sent To:
                          <ul>
                            {signRequestHistory.SentTo.map((r, i) => {
                              return <li key={`sent-to-${i}`}>{r}</li>;
                            })}
                          </ul>
                        </div>
                      )}
                      <hr />
                      <div>
                        <div className="flex-between">
                          <div>
                            <strong>Sign Now Link:</strong> <>{signRequestHistory.ShortSignRequestUrl}</>
                          </div>
                          <button
                            type="button"
                            className="btn btn-xs btn-secondary"
                            onClick={() => {
                              window.navigator.clipboard.writeText(`${signRequestHistory.ShortSignRequestUrl}`);
                            }}
                            title={`Click to copy link`}>
                            <Icon type={IconType.copy} /> Copy Link
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <h4>Request Signature</h4>
                  What is the best way to contact the person that needs to sign?
                  <div className="flex-centered request-sig-options">
                    <div
                      className={`sig-option ${form.sendEmail ? 'selected' : ''}`}
                      onClick={() => {
                        setForm({
                          ...form,
                          sendEmail: !(form.sendEmail === true),
                        });
                      }}>
                      Email
                    </div>
                    <div
                      className={`sig-option ${form.sendText ? 'selected' : ''}`}
                      onClick={() => {
                        setForm({ ...form, sendText: !(form.sendText === true) });
                      }}>
                      Text Message
                    </div>
                    <div
                      className={`sig-option ${form.sendLink ? 'selected' : ''}`}
                      onClick={() => {
                        setForm({ ...form, sendLink: !(form.sendLink === true) });
                      }}>
                      Link
                    </div>
                  </div>
                  <div>
                    <div className="form-horizontal">
                      {form.sendEmail && (
                        <>
                          <div className="form-group">
                            <label className="col-sm-4 control-label" htmlFor="request-sig-email">
                              Email
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="email"
                                id={`request-sig-email`}
                                className="form-control"
                                maxLength={250}
                                value={form.email}
                                onChange={(e) => {
                                  setForm({ ...form, email: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-sm-4 control-label" htmlFor="request-sig-email">
                              Message
                            </label>
                            <div className="col-sm-8">
                              <Editor
                                options={'simple'}
                                height={'short'}
                                value={form.message}
                                changed={(v) => {
                                  setForm({ ...form, message: v });
                                }}
                              />
                              {form.message && form.message.indexOf('[LINK]') === -1 && <span className="label label-danger">[LINK] is required to be part of the message.</span>}
                            </div>
                          </div>
                        </>
                      )}
                      {form.sendText && (
                        <>
                          <div className="form-group">
                            <label className="col-sm-4 control-label" htmlFor="request-sig-phone">
                              Phone Number
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="tel"
                                id={`request-sig-phone`}
                                className="form-control"
                                maxLength={15}
                                value={form.phone}
                                onChange={(e) => {
                                  setForm({ ...form, phone: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-sm-4 control-label" htmlFor="request-sig-phone">
                              Text Message
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                id={`request-sig-phone`}
                                className="form-control"
                                maxLength={110}
                                value={form.textMessage}
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    textMessage: e.target.value,
                                  });
                                }}
                              />
                              {form.textMessage && form.textMessage.indexOf('[LINK]') === -1 && <span className="label label-danger">[LINK] is required to be part of the message.</span>}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-offset-4 col-sm-8">
                    <button type="button" className="btn btn-secondary" onClick={sendSignRequest}>
                      <Icon type={IconType.send} /> Request / Send
                    </button>
                  </div>
                </>
              )}
              {signRequestedInfo && (
                <div className="headroom-xl alert alert-success">
                  <h3>Signature Request Processed</h3>
                  <hr />
                  {signRequestedInfo.EmailSent && (
                    <div>
                      <Icon type={IconType.mail} /> Email sent to <strong>{form.email}</strong>
                    </div>
                  )}
                  {signRequestedInfo.TextSent && (
                    <div>
                      <Icon type={IconType.textMsg} /> Text message sent to <strong>{form.phone}</strong>
                    </div>
                  )}
                  <div className="headroom-xl" />
                  <div className="flex-between">
                    <div>
                      <strong>Sign Now Link:</strong> <>{signRequestedInfo.Link}</>
                    </div>
                    <button
                      type="button"
                      className="btn btn-xs btn-secondary"
                      onClick={() => {
                        window.navigator.clipboard.writeText(`${signRequestedInfo.Link}`);
                      }}
                      title={`Click to copy link`}>
                      <Icon type={IconType.copy} /> Copy Link
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )}

        {showSignerModal && (
          <Modal setModalOpen={setShowSignerModal} title={'Signers Information'} size="l" noClose={true && !packet?.Self?.IsSelf}>
            <h3>{selectedSignerInfo?.Name} Electronic Signing</h3>
            <div>
              Signing electronicly is a valid form of signature and is considered the same as signing using pen and paper. By procceding you understand that your signature is binding, and that the
              miss use of electronic signatures is fruad.
            </div>
            <h4>Signer Information</h4>
            <div className="form-horizontal">
              <div className="form-group">
                <label className="control-label col-sm-4" htmlFor="signerName">
                  Name
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="signerName"
                    readOnly={isSelfSigning}
                    maxLength={50}
                    autoFocus={true}
                    value={form.signerName}
                    onChange={(e) => {
                      setForm({ ...form, signerName: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-sm-4" htmlFor="signerInitial">
                  Initials
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    id="signerInitial"
                    maxLength={10}
                    value={form.signerInitial}
                    onChange={(e) => {
                      setForm({ ...form, signerInitial: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-sm-4" htmlFor="signerEmail">
                  Email
                </label>
                <div className="col-sm-7">
                  <input
                    type="email"
                    className="form-control"
                    id="signerEmail"
                    maxLength={250}
                    value={form.signerEmail}
                    onChange={(e) => {
                      setForm({ ...form, signerEmail: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-sm-4" htmlFor="signerPhone">
                  Cell Phone
                </label>
                <div className="col-sm-4">
                  <input
                    type="tel"
                    className="form-control"
                    id="signerPhone"
                    maxLength={20}
                    value={form.signerPhone}
                    onChange={(e) => {
                      setForm({ ...form, signerPhone: e.target.value });
                    }}
                  />
                  {form.signerPhone && <span className="label label-info">A number where we can send text messages to confirm your identity.</span>}
                </div>
              </div>

              <div className="form-group">
                <div className="col-sm-8 col-sm-offset-4">
                  <input
                    type="checkbox"
                    className=""
                    id="signerAgree"
                    maxLength={20}
                    checked={form.signerAgree}
                    onChange={(e) => {
                      setForm({ ...form, signerAgree: e.target.checked });
                    }}
                  />
                  <label className="" htmlFor="signerAgree">
                    I understand that this is my electronic signature and is binding.
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="headroom-xl">Click on any form to review and sign. Forms needing your signature and or information have been highlighted.</div>
                <div className="headroom-xl">
                  <button type="button" className="btn btn-secondary" title="Click to agree and sign" onClick={agreeAndSign}>
                    <Icon type={IconType.sign} /> I Agree & Start Signing
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {showWizardModal && packet && (
          <Modal setModalOpen={setShowWizardModal} title={`${packet?.Name} Forms Wizard`} size="xl">
            <QuestionWizard
              packet={packet}
              values={packet.WizardValues}
              save={saveWizard}
              close={() => {
                setShowWizardModal(false);
              }}
            />
          </Modal>
        )}

        {showReviewAndCommentModal && packet && (
          <Modal setModalOpen={setShowReviewAndCommentModal} title={`${packet?.Name} Review & Comment`} size="l">
            <div className="form-horizontal">
              <div className="form-group">
                <label htmlFor="review-comment" className="control-label col-sm-4">
                  Comment
                </label>
                <div className="col-sm-8">
                  <textarea
                    id="review-comment"
                    value={form.reviewComment}
                    rows={5}
                    className="form-control"
                    onChange={(e) => {
                      setForm({ ...form, reviewComment: e.target.value });
                    }}></textarea>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="review-status" className="control-label col-sm-4">
                  Status
                </label>
                <div className="col-sm-8">
                  <select
                    id="review-status"
                    value={form.reviewStatus}
                    className={'form-control'}
                    onChange={(e) => {
                      setForm({ ...form, reviewStatus: e.target.value });
                    }}>
                    <option value={'unknown'}>Select</option>
                    <option value={'NeedsWork'}>Needs Work</option>
                    <option value={'UnderReview'}>Under Review</option>
                    <option value={'Accepted'}>Accepted</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-offset-4 col-sm-8">
                  <button type="button" onClick={updateStatus} className="btn btn-secondary">
                    <Icon type={IconType.save} /> Save
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
};

export default withRouter(GenericFormsLanding);
