import { useState } from "react";
import { toast } from "react-toastify";
import StoreX, { store } from "../../../redux/oldStore";
import { Server } from "../../../utils/Server";
import Modal from "../../Modal/Modal";
import BranchContainer from "../Branches/BranchContainer";
import BranchPortal from "../Branches/BranchPortal";
import ConfigFormLanding from "../ConfigForm/ConfigFormLanding";
import { IState, ReduxMap } from "../../../redux/redux";

interface IOldSettingsLayoutProps extends IState {

}

const OldSettingsLayout  = (props:IOldSettingsLayoutProps)=> {
    const [showConfigModal, setShowConfigModal] = useState<boolean>(false);
    const [showBranchModal, setShowBranchModal] = useState<boolean>(false);

    const [configType, setConfigType] = useState<string>();
    
    const ShowMainModal = (type:string) =>{
        console.log(type);
        setShowConfigModal(true);
        setConfigType(type);
    };

    const ShowBranchModal = (type:string) =>{
        console.log(type);
        setShowBranchModal(true);
        setConfigType(type);
    };

    const GoTo = (path:string) =>{
        let url = StoreX.BuildUrlWithFair('../Payout.aspx');
        window.location.href= url;
    };

    return (<>
    
    <div>
    <div className="col-sm-4">
        <div className="panel panel-primary">
            <div className="panel-heading"><strong>Start Here!</strong> - Set the basics</div>
            <div className="panel-body">
                <div>
                    <div className="div-button" onClick={()=>{ShowBranchModal('[standalone general]')}}>
                        <label><span className="fa fa-cog"></span>&nbsp;General Information</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowBranchModal('[standalone-schedule]')}} >
                        <label><i className="fa fa-calendar"></i>&nbsp;Schedule</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowBranchModal('[standalone-settings-settings]')}} >
                        <label><i className="fa fa-cogs"></i>&nbsp;Settings</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('categories')}} >
                        <label><span className="fa fa-layer-group"></span>&nbsp;Categories</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowBranchModal('[standalone-custom-text]')}} >
                        <label><i className="fa fa-file-text"></i>&nbsp;Customize Text</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('requirements')}} >
                        <label><i className="fa fa-flag"></i>&nbsp;Guidelines & Requirements</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="col-sm-4">
        <div className="panel panel-default">
            <div className="panel-heading"><strong>Ask Your Own Questions</strong></div>
            <div className="panel-body">
                <div>
                    <div className="div-button" onClick={()=>{ShowMainModal('custom-questions-participant')}} >
                        <label><span className="fa fa-fingerprint"></span>&nbsp; Student's Custom Questions</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('custom-questions-entry')}} >
                        <label><span className="fa fa-fingerprint"></span>&nbsp; Project's Custom Questions</label>
                    </div>
                    <br />
                    <div className="div-button" onClick={()=>{ShowMainModal('custom-questions-teacher')}} >
                        <label><span className="fa fa-fingerprint"></span>&nbsp; Teacher's Custom Questions</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('custom-questions-judge')}} >
                        <label><span className="fa fa-fingerprint"></span>&nbsp; Judge's Custom Questions</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('custom-questions-volunteer')}} >
                        <label><span className="fa fa-fingerprint"></span>&nbsp; Volunteer's Custom Questions</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('custom-questions-attendee')}} >
                        <label><span className="fa fa-fingerprint"></span>&nbsp; Attendee's Custom Questions</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('custom-questions')}} >
                        <label><span className="fa fa-fingerprint"></span>&nbsp; All Custom Questions</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="col-sm-4">
        <div className="panel panel-default">
            <div className="panel-heading"><strong>Divisions & Awards</strong></div>
            <div className="panel-body">
                <div>
                <div className="div-button" onClick={()=>{ShowMainModal('divisions')}} >
                        <label><span className="fa fa-users"></span>&nbsp;Contest Divisions</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('awards')}} >
                        <label><span className="fa fa-star"></span>&nbsp; Category Awards</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('awards-special')}} >
                        <label><span className="fa fa-star"></span>&nbsp; Special Awards</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('awards-special-donors')}}>
                        <label><i className="fa fa-users"></i>&nbsp; Special Award Donors</label>
                    </div>
                    {!(props.Settings?.isHistoryContest ?? false) &&<><div className="div-button" onClick={()=>{ShowMainModal('sub-categories')}} >
                        <label><span className="fa fa-layer-group"></span>&nbsp; Sub-Categories</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('sub-sub-categories')}} >
                        <label><span className="fa fa-layer-group"></span>&nbsp; Sub-Sub-Categories</label>
                    </div>
 
                    <div className="div-button <%=hideIfHistroy %>" onClick={()=>{ShowMainModal('session-judge')}} >
                        <label><span className="fa fa-object-group"></span>&nbsp; Judging Sessions</label>
                    </div>
                    <div className="div-button <%=hideIfNotScienceclassName %>" onClick={()=>{ShowMainModal('session-volunteer')}} >
                        <label><span className="fa fa-object-group"></span>&nbsp; Volunteer Sessions</label>
                    </div></>}
                </div>
            </div>
        </div>
    </div>
    <div className="clearfix"></div>
    <div className="col-sm-4">
        <div className="panel panel-default">
            <div className="panel-heading"><strong>Other</strong></div>
            <div className="panel-body">
                <div>
                   
                    <div className="div-button" onClick={()=>{ShowBranchModal('[standalone-site-theme]')}} >
                        <label><span className="fa fa-cog"></span>&nbsp;Custom Styling</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('text')}} >
                        <label><i className="fa fa-edit"></i>&nbsp; Customize System Text</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('shirt-sizes')}} >
                        <label><span className="fa fa-ruler"></span>&nbsp; Shirt-Sizes</label>
                    </div>
                    {!(props.Settings?.isHistoryContest ?? false) &&<>
                    <div className="div-button" onClick={()=>{ShowMainModal('school-districts')}} >
                        <label><i className="fa fa-university"></i>&nbsp;School Districts</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('schools')}} >
                        <label><i className="fa fa-school"></i>&nbsp;Schools</label>
                    </div>
                    <div className="div-button <%=hideIfNotScienceclassName %>" onClick={()=>{ShowBranchModal('[standalone-media-release]')}} >
                        <label><i className="fa fa-camera"></i>&nbsp; Media Release</label>
                    </div>
                    <div className="div-button <%=hideIfNotScienceclassName %>" onClick={()=>{ShowBranchModal('[standalone-hold-harmless]')}} >
                        <label><i className="fa fa-edit"></i>&nbsp; Hold Harmless</label>
                    </div>
                    </>}
                </div>
            </div>
        </div>
    </div>
    <div className="col-sm-4">
        <div className="panel panel-default">
            <div className="panel-heading"><strong>Payment Tools</strong></div>
            <div className="panel-body">
                <div>
                    <div className="div-button" onClick={()=>{ShowBranchModal('[Merchant]')}} >
                        <label><i className="fa fa-money"></i>&nbsp; Merchant Account</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('ecommerce')}} >
                        <label><i className="fa fa-shopping-bag"></i>&nbsp; E-Commerce</label>
                    </div>
                    {/* <div className="div-button" onClick={()=>{ShowMainModal('TestEmailTool')}} >
                        <label><i className="fa fa-envelope-o"></i>&nbsp;*Test Email</label>
                    </div> */}
                    {/* <div className="div-button" onClick={()=>{warningsManager.Show(this);">
                        <la}el><i className="fa fa-exclamation-triangle"></i>&nbsp; Warnings - Beta</label>
                    </div>
                    <div className="div-button" onClick={()=>{businessEntityManager.Show(this);">
                        <la}el><i className="fa fa-money"></i>&nbsp; Payout Request</label>
                    </div> */}
                
                    <div className="div-button" onClick={()=>{GoTo('../Payout.aspx')}} >
                        <label><i className="fad fa-sack-dollar"></i>&nbsp; Payout Request</label>
                    </div>
                    <div className="div-button" onClick={()=>{ShowMainModal('coupons')}} >
                        <label><i className="fa fa-ticket"></i>&nbsp;Coupons / Subsidy</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="col-sm-4">
    </div>
    </div>

{configType && showConfigModal && <Modal setModalOpen={setShowConfigModal} title={'Setup Tools'} size={'xxl'}>
        <ConfigFormLanding type={configType} />
    </Modal>}
    {configType && showBranchModal && <Modal setModalOpen={setShowBranchModal} title={'Setup Tools'} size={'xl'}>
        <BranchPortal type={configType} />
    </Modal>}

    </>);
}

export default ReduxMap(OldSettingsLayout);
