import { RouteComponentProps } from 'react-router-dom';
import { IState, ReduxMap } from '../../redux/redux';
import { useEffect, useState } from 'react';
import { ISchoolAffiliateInfo, ISchoolInfo } from './schoolTypes';
import { store } from '../../redux/oldStore';
import { toast } from 'react-toastify';
import { ServerResponse } from '../../utils/Server';
import Button from '../_Core/Button';
import { FormatPhoneNumber } from '../../utils/Tools';

interface IProps {
  state: IState;
  data: ISchoolInfo;
  record: ISchoolAffiliateInfo;
  footerText?:string;
  saveBtnText?:string;
  overrideSave?:boolean;
  onRemoved: (x: ISchoolAffiliateInfo) => void;
  onSaved: (x: ISchoolAffiliateInfo) => void;
  onCanceled?: () => void;
}

const SchoolAffiliation = (props: IProps) => {
  const [show, setShow] = useState<'landing' | 'review' | 'done'>();
  const [form, setForm] = useState<ISchoolAffiliateInfo>({});
  const [data, setData] = useState<ISchoolInfo>(props.data);

  useEffect(() => {
    if (props.record) {
      setForm(props.record);
    }
  }, [props.record]);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const init = () => {
    //do stuff...
  };

  const save = () => {
    toast.dismiss();
    let isStaff = props.state.User?.Admin ?? false;

    if(props.overrideSave) {
        props.onSaved(form);
        return;
    }

    let errors: string[] = [];
    if (!form.SchoolId || form.SchoolId <= 0) errors.push('You must select a school.');

    //hard stop...
    if (errors.length > 0) {
      toast.warning(
        <ul>
          {errors.map((x, i) => {
            return <li key={i}>{x}</li>;
          })}
        </ul>
      );
      return;
    }

    console.log(form);
    store.server.postApi<ServerResponse<ISchoolAffiliateInfo>>(`../SchoolAffiliate/Save`, form).then((x) => {
      if (x.Success) {
        toast.success('Affiliate information saved.');
        setForm(x.Value);
        props.onSaved(x.Value);
      } else {
        toast.error(x.Message);
      }
    });
  };

  const remove = () => {
    toast.dismiss();
    if (!window.confirm(`Are you sure you wan't do remove this School Affiliation record?`)) {
      return;
    }
    store.server.postApi<ServerResponse<ISchoolAffiliateInfo>>(`../SchoolAffiliate/Remove`, form).then((x) => {
      if (x.Success) {
        toast.success('Affiliate information Removed.');
        props.onRemoved(x.Value);
      } else {
        toast.error(x.Message);
      }
    });
  };

  return (
    <>
      {props.data && (
        <div className="form-horizontal">
          <h3>Core Affiliate Information</h3>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="SchoolId">
              School
            </label>
            <div className="col-sm-7">
              <select
                id={'SchoolId'}
                className="form-control"
                value={(form.SchoolId ?? '') + ''}
                onChange={(x) => {
                  if (x.target.value === '0') setForm({ ...form, SchoolId: undefined });
                  else setForm({ ...form, SchoolId: +x.target.value });
                }}
                disabled={!data.isStaff}>
                <option value={'0'}>Select...</option>
                {data.schools?.map((x, i) => {
                  return <option value={x.id + ''}>{x.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="Division">
              Division
            </label>
            <div className="col-sm-7">
              <select
                id={'Division'}
                className="form-control"
                value={(form.Division ?? '') + ''}
                onChange={(x) => {
                  if (x.target.value === '0') setForm({ ...form, Division: undefined });
                  else setForm({ ...form, Division: +x.target.value });
                }}
                disabled={!data.isStaff}>
                <option value={'0'}>Select...</option>
                {data.divisions?.map((x, i) => {
                  return <option value={x.id + ''}>{x.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="SchoolCode">
              School Code
            </label>
            <div className="col-sm-7">
              <input
                id={'SchoolCode'}
                className="form-control"
                type="text"
                value={form.SchoolCode ?? ''}
                onChange={(x) => {
                  setForm({ ...form, SchoolCode: x.target.value });
                }}
                maxLength={20}
                readOnly={!data.isStaff}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="SchoolUpdatePassword">
              Update Password
            </label>
            <div className="col-sm-7">
              <input
                id={'SchoolUpdatePassword'}
                className="form-control"
                type="text"
                value={form.SchoolUpdatePassword ?? ''}
                onChange={(x) => {
                  setForm({ ...form, SchoolUpdatePassword: x.target.value });
                }}
                maxLength={20}
                readOnly={!data.isStaff}
              />
            </div>
          </div>
          { data.isStaff && <>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="MaxNumberOfAllowedEntries">
            Override Max Number Allowed Entries
            </label>
            <div className="col-sm-7">
              <input
                id={'MaxNumberOfAllowedEntries'}
                className="form-control"
                type="number"
                step={1}
                value={form.MaxNumberOfAllowedEntries || data.numberOfEntries}
                onChange={(x) => {
                  setForm({ ...form, MaxNumberOfAllowedEntries: +x.target.value });
                }}
                maxLength={10}
                placeholder='leave blank or -1 for default limit'
                readOnly={!data.isStaff}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="MaxNumberOfAllowedTeams">
              Override Max Number Allowed Teams
            </label>
            <div className="col-sm-7">
              <input
                id={'MaxNumberOfAllowedTeams'}
                className="form-control"
                type="number"
                step={1}
                value={form.MaxNumberOfAllowedTeams || data.numberOfEntries}
                onChange={(x) => {
                  setForm({ ...form, MaxNumberOfAllowedTeams: +x.target.value });
                }}
                maxLength={10}
                readOnly={!data.isStaff}
                placeholder='leave blank or -1 for default limit'
              />
            </div>
          </div>
          </>}
          {data.isStaff && (
            <div className="form-group">
              <label className="control-label col-sm-4" htmlFor="StaffNotes">
                Staff Notes
              </label>
              <div className="col-sm-7">
                <textarea
                  id={'StaffNotes'}
                  className="form-control"
                  value={form.StaffNotes ?? ''}
                  onChange={(x) => {
                    setForm({ ...form, StaffNotes: x.target.value });
                  }}
                  maxLength={20000}
                  readOnly={!data.isStaff}
                  rows={6}
                />
              </div>
            </div>
          )}
          <hr />
          <h3>Affiliate Information</h3>
          <div className="form-group">
            <div className="col-sm-7 col-sm-offset-4">
              <input
                id={'SchoolIsPaying'}
                // className="form-control"
                type="checkbox"
                checked={form.SchoolIsPaying ?? false}
                onChange={(x) => {
                  setForm({ ...form, SchoolIsPaying: x.target.checked });
                }}
                maxLength={100}
              />
              <label className="control-label" htmlFor="SchoolIsPaying">
                School is paying participant registration fees.
              </label>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="Season">
              Season
            </label>
            <div className="col-sm-7">
              <input
                id={'Season'}
                className="form-control"
                maxLength={25}
                value={(form.Season ?? '') + ''}
                onChange={(x) => {
                  if (x.target.value === '0') setForm({ ...form, Season: undefined });
                  else setForm({ ...form, Season: x.target.value });
                }}
                disabled={!data.isStaff} />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="EstimatedNumberOfEntries">
              Estimated Number of Projects
            </label>
            <div className="col-sm-7">
              <input
                id={'EstimatedNumberOfEntries'}
                className="form-control"
                type="number"
                value={form.EstimatedNumberOfEntries ?? ''}
                onChange={(x) => {
                  setForm({ ...form, EstimatedNumberOfEntries: +x.target.value });
                }}
                maxLength={10}
                step={1}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="EstimatedNumberOfParticipants">
              Estimated Number of Participants
            </label>
            <div className="col-sm-7">
              <input
                id={'EstimatedNumberOfParticipants'}
                className="form-control"
                type="number"
                value={form.EstimatedNumberOfParticipants ?? ''}
                onChange={(x) => {
                  setForm({ ...form, EstimatedNumberOfParticipants: +x.target.value });
                }}
                maxLength={10}
                step={1}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="EstimatedNumberOfTeams">
              Estimated Number of Teams
            </label>
            <div className="col-sm-7">
              <input
                id={'EstimatedNumberOfTeams'}
                className="form-control"
                type="number"
                value={form.EstimatedNumberOfTeams ?? ''}
                onChange={(x) => {
                  setForm({ ...form, EstimatedNumberOfTeams: +x.target.value });
                }}
                maxLength={10}
                step={1}
              />
            </div>
          </div>
          <hr />
          <h3>Billing Office Information</h3>
          Tell us about your billing office.
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="BillingOfficeName">
              Contact Name
            </label>
            <div className="col-sm-7">
              <input
                id={'BillingOfficeName'}
                className="form-control"
                type="text"
                value={form.BillingOfficeContact ?? ''}
                onChange={(x) => {
                  setForm({ ...form, BillingOfficeContact: x.target.value });
                }}
                maxLength={100}
                placeholder="Jane Doe"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="BillingOfficeEmail">
              Email
            </label>
            <div className="col-sm-7">
              <input
                id={'BillingOfficeEmail'}
                className="form-control"
                type="text"
                value={form.BillingOfficeEmail ?? ''}
                onChange={(x) => {
                  setForm({ ...form, BillingOfficeEmail: x.target.value });
                }}
                maxLength={100}
                placeholder="jane@example.com"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="BillingOfficePhone">
              Phone
            </label>
            <div className="col-sm-7">
              <input
                id={'BillingOfficePhone'}
                className="form-control"
                type="text"
                value={form.BillingOfficePhone ?? ''}
                onChange={(x) => {
                  setForm({ ...form, BillingOfficePhone: FormatPhoneNumber(x.target.value) });
                }}
                maxLength={25}
                placeholder="(000) 000-0000"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="BillingOfficeFax">
              Fax
            </label>
            <div className="col-sm-7">
              <input
                id={'BillingOfficeFax'}
                className="form-control"
                type="text"
                value={form.BillingOfficeFax ?? ''}
                onChange={(x) => {
                  setForm({ ...form, BillingOfficeFax: FormatPhoneNumber(x.target.value)});
                }}
                maxLength={25}
                placeholder="(000) 000-0000"
              />
            </div>
          </div>
          <hr />
          <h3>IRB Member Information</h3>
          Tell us about your IRB Members.
          
          <div className="form-group">
            <div className="col-sm-7 col-sm-offset-4">
              <input
                id={'NoIrb'}
                // className="form-control"
                type="checkbox"
                checked={form.NoIrb ?? false}
                onChange={(x) => {
                  setForm({ ...form, NoIrb: x.target.checked });
                }}
                maxLength={100}
              />
              <label className="control-label" htmlFor="NoIrb">
                Our school does NOT have an IRB and will not have human subject projects.
              </label>
            </div>
          </div>
          {!form.NoIrb && <>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbAdminName">
              Admin Name
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbAdminName'}
                className="form-control"
                type="text"
                value={form.IrbAdminName ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbAdminName: x.target.value });
                }}
                maxLength={100}
                placeholder="Jane Doe"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbAdminEmail">
              Admin Email
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbAdminEmail'}
                className="form-control"
                type="text"
                value={form.IrbAdminEmail ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbAdminEmail: x.target.value });
                }}
                maxLength={100}
                placeholder="jane@example.com"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbAdminPhone">
              Admin Phone
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbAdminPhone'}
                className="form-control"
                type="text"
                value={form.IrbAdminPhone ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbAdminPhone: FormatPhoneNumber(x.target.value) });
                }}
                maxLength={25}
                placeholder="(000) 000-0000"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbMedName">
              Medical Name
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbMedName'}
                className="form-control"
                type="text"
                value={form.IrbMedName ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbMedName: x.target.value });
                }}
                maxLength={100}
                placeholder="Jane Doe"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbMedEmail">
              Medical Email
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbMedEmail'}
                className="form-control"
                type="text"
                value={form.IrbMedEmail ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbMedEmail: x.target.value });
                }}
                maxLength={100}
                placeholder="jane@example.com"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbMedPhone">
              Medical Phone
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbMedPhone'}
                className="form-control"
                type="text"
                value={form.IrbMedPhone ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbMedPhone: FormatPhoneNumber(x.target.value) });
                }}
                maxLength={25}
                placeholder="(000) 000-0000"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbEducatorName">
              Science Teacher 1 Name
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbEducatorName'}
                className="form-control"
                type="text"
                value={form.IrbEducatorName ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbEducatorName: x.target.value });
                }}
                maxLength={100}
                placeholder="Jane Doe"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbEducatorEmail">
              Science Teacher 1 Email
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbEducatorEmail'}
                className="form-control"
                type="text"
                value={form.IrbEducatorEmail ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbEducatorEmail: x.target.value });
                }}
                maxLength={100}
                placeholder="jane@example.com"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbEducatorPhone">
              Science Teacher 1 Phone
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbEducatorPhone'}
                className="form-control"
                type="text"
                value={form.IrbEducatorPhone ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbEducatorPhone: FormatPhoneNumber(x.target.value) });
                }}
                maxLength={25}
                placeholder="(000) 000-0000"
              />
            </div>
          </div>


          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbEducator2Name">
              Science Teacher 2 Name
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbEducator2Name'}
                className="form-control"
                type="text"
                value={form.IrbEducator2Name ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbEducator2Name: x.target.value });
                }}
                maxLength={100}
                placeholder="Jane Doe"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbEducator2Email">
              Science Teacher 2 Email
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbEducator2Email'}
                className="form-control"
                type="text"
                value={form.IrbEducator2Email ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbEducator2Email: x.target.value });
                }}
                maxLength={100}
                placeholder="jane@example.com"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4" htmlFor="IrbEducator2Phone">
              Science Teacher 2 Phone
            </label>
            <div className="col-sm-7">
              <input
                id={'IrbEducator2Phone'}
                className="form-control"
                type="text"
                value={form.IrbEducator2Phone ?? ''}
                onChange={(x) => {
                  setForm({ ...form, IrbEducator2Phone: FormatPhoneNumber(x.target.value) });
                }}
                maxLength={25}
                placeholder="(000) 000-0000"
              />
            </div>
          </div>
          </>}

          <hr />
          <div>
            {props.footerText && <div dangerouslySetInnerHTML={{__html:props.footerText}}/>}
          </div>
          <div className="form-group">
            <div className="col-sm-offset-4 col-sm-7">
              <Button type="save" text={props.saveBtnText ?? "Save"} onClick={save} />
              {form.PublicId && <Button type="delete" text="Remove" onClick={remove} />}
              {props.onCanceled && (
                <Button
                  type="cancel"
                  text="Cancel"
                  onClick={() => {
                    if (props.onCanceled) props.onCanceled();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SchoolAffiliation;
